/* This component is to render barChart it expects the data in the format:
const barChartData = [
  { name: 'Jan 1', electricMeterValue: 400, electricSolarValue: 200 },
  { name: 'Feb 1', electricMeterValue: 300, electricSolarValue: 500 },
];
It will show the bars on the graph only when the data is passed in the above way and also the xAxis dataKey is set to name */

import React from 'react';
import {
    BarChart,
    ResponsiveContainer,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Bar,
    Label,
    Cell,
    Legend
} from 'recharts';
import { barChartColors } from './chartHelpers';
import { FlexCenter, FlexRow } from '../styles';

const transformData = (barChartData: any) => {
    if (!!!barChartData?.length) {
        return [];
    }

    const allPropertyNames: string[] = [];

    barChartData.forEach((item: any) => {
        Object.keys(item).forEach((propertyName: string) => {
            if (propertyName !== 'name' && !allPropertyNames.includes(propertyName)) {
                allPropertyNames.push(propertyName);
            }
        });
    });

    return barChartData.map((item: any) => {
        const transformedItem: Record<string, number> = {
            name: item.name
        };

        allPropertyNames.forEach((propertyName: string) => {
            transformedItem[propertyName] = item[propertyName] || 0;
        });

        return transformedItem;
    });
};

export const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
        return payload.map((item: any) => (
            <div className='custom-tooltip bg-light m-3 p-2'>
                <div className='label'>{item.payload.name}</div>
                <div>
                    {item.name} : {item.value}
                </div>
            </div>
        ));
    }
    return null;
};

const getBars = ({ barChartData, barChartColors, stackedBarChart, chartData, onClick }: any) => {
    const barCharts: any = [];
    if (barChartData) {
        Object.entries(barChartData)
            .filter(([key]) => (stackedBarChart ? key !== 'name' : key === 'value'))
            .forEach(([item]: any, index: any) => {
                barCharts.push(
                    <Bar
                        dataKey={item}
                        key={index + 'bar'}
                        fill={barChartColors[index]}
                        label={stackedBarChart ? '' : { fill: 'black', position: 'top' }}
                        barSize={100}
                        stackId={stackedBarChart ? 'stackbar' : undefined}
                        onClick={onClick && onClick}
                    >
                        {!stackedBarChart &&
                            chartData?.map((_entry: any, index: number) => (
                                <Cell key={`cell-${index}`} fill={barChartColors[index]} />
                            ))}
                    </Bar>
                );
            });
    }
    return barCharts;
};

const getLegendData = (barChartData: any, stackedBarChart: boolean) => {
    if (stackedBarChart)
        return Object.keys(transformData(barChartData)?.[0] || {})
            .filter((key) => key !== 'name')
            .map((key) => ({ name: key }));
    return barChartData;
};

const CustomizedXAxisLabel = (props: any) => {
    const { x, y, payload, width, visibleTicksCount } = props;

    const labelWidth = width / visibleTicksCount;

    return (
        <g transform={`translate(${x},${y})`}>
            <foreignObject
                x={-labelWidth / 2}
                y={0}
                width={labelWidth - (5 * visibleTicksCount) / 2}
                height={30}
            >
                <div
                    title={payload.value}
                    style={{
                        fontSize: '14px',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer',
                        textAlign: 'center',
                        marginRight: '5px'
                    }}
                >
                    {payload.value}
                </div>
            </foreignObject>
        </g>
    );
};

const formatYAxisTick = (tick: any) => {
    if (tick >= 1000000) {
        return `${tick / 1000000}M`; // Display in millions
    } else if (tick >= 1000) {
        return `${tick / 1000}K`; // Display in thousands
    } else {
        return tick; // For values less than 1000
    }
};

const BarCharts = (props: any) => {
    const {
        containerHeight,
        chartTitle,
        barChartData,
        yAxisTickCount,
        yAxisLabel,
        xAxisLabel,
        barChartColors,
        xAxisInterval = 0,
        stackedBarChart,
        onClick,
        styles
    } = props;

    return (
        <div style={{ width: '100%', height: containerHeight, ...styles }}>
            <ResponsiveContainer>
                <BarChart
                    data={barChartData}
                    margin={{
                        top: 40,
                        right: 20,
                        left: -20,
                        bottom: 20
                    }}
                >
                    <text
                        x={'50%'}
                        y={10}
                        fill='black'
                        textAnchor='middle'
                        dominantBaseline='central'
                    >
                        <tspan fontSize='20'>{chartTitle}</tspan>
                    </text>
                    <CartesianGrid vertical={false} />
                    <XAxis
                        padding={{ left: 15, right: 10 }}
                        dataKey={'name'}
                        hide={!!!stackedBarChart}
                        interval={xAxisInterval}
                        tick={<CustomizedXAxisLabel />}
                    >
                        <Label value={xAxisLabel} position='bottom' offset={0} />
                    </XAxis>
                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        tickCount={yAxisTickCount}
                        width={80}
                        tickFormatter={(tickLabel) => formatYAxisTick(tickLabel)}
                    >
                        <Label
                            value={yAxisLabel}
                            angle={-90}
                            style={{ textAnchor: 'middle' }}
                            position='insideLeft'
                            offset={1}
                        />
                    </YAxis>
                    <Tooltip
                        content={<CustomTooltip />}
                        cursor={{ fill: 'transparent' }}
                        shared={false}
                    />
                    <Legend
                        align='right'
                        verticalAlign='bottom'
                        content={() => {
                            return (
                                <FlexCenter className='flex-wrap mt-4 ms-3'>
                                    {getLegendData(barChartData, stackedBarChart).map(
                                        (entry: any, index: number) => (
                                            <FlexCenter className='me-3'>
                                                <div
                                                    style={{
                                                        width: '12px',
                                                        height: '12px',
                                                        backgroundColor: barChartColors[index],
                                                        cursor: 'pointer',
                                                        marginRight: '5px'
                                                    }}
                                                />
                                                <FlexRow
                                                    key={`item-${index}`}
                                                    style={{ color: barChartColors[index] }}
                                                >
                                                    {entry.name}
                                                </FlexRow>
                                            </FlexCenter>
                                        )
                                    )}
                                </FlexCenter>
                            );
                        }}
                    />
                    {getBars({
                        barChartData: transformData(barChartData)?.[0],
                        barChartColors,
                        stackedBarChart,
                        chartData: barChartData,
                        onClick
                    })}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BarCharts;

BarCharts.defaultProps = {
    xAxisLabel: '',
    yAxisLabel: '',
    barChartColors: barChartColors,
    yAxisUnit: '',
    yAxisTickCount: 5,
    containerHeight: 400,
    chartTitle: ''
};
