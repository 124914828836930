import styled from 'styled-components';
import { fontSize_SM } from '../../customFonts';

export const MapContainer = styled.div`
    display: flex;

    .custom-popup {
        .mapboxgl-popup-content {
            width: 350px;
            border-radius: 8px;
            max-height: 200px;
            overflow-y: auto;
        }
    }

    &:fullscreen .custom-popup .mapboxgl-popup-content {
        max-height: none;
        overflow-y: visible;
    }
`;

export const PopUpContainer = styled.div`
    display: flex;
    flex-direction: column;

    .row {
        display: flex;
        background-color: white;
        ${fontSize_SM}
    }

    .row:nth-child(even) {
        background-color: #f8fafd;
    }
`;
