import styled, { css } from 'styled-components';
import { theme } from '../theme';
import {
    closeIconWidth,
    customCheckBoxDimension,
    entryInputHeight,
    secondaryProfileDimension,
    tabItemHeight,
    tabItemActiveWidth,
    tabItemInActiveWidth,
    userProfileIconWidth
} from '../variables';
import {
    fontSize_XS,
    fontSize_SM,
    fontSize_BASE,
    fontSize_LG,
    fontSize_XL,
    fontSize_XXL,
    size,
    fontSize_XXXL,
    fontSize_XXS
} from './customFonts';
import { device } from './responsiveBreakpoints';

interface Selected {
    isViewChange?: boolean;
    selected?: boolean;
    Disabled?: boolean;
    isInform?: true;
    isWebBooking?: boolean;
    isBorder?: boolean;
    isStepperUI?: boolean;
}

interface CheckboxProps {
    isDisabled?: boolean;
    isTextPrimary?: boolean;
}

interface CollapseProps {
    isOpen?: boolean;
    isCollapsedTest?: boolean;
}
interface CrossIconProps {
    isColorDark?: boolean;
}

interface BadgeProps {
    isSuccess?: boolean;
}

interface FormPrimaryContainerProps {
    border?: boolean;
    cardFixedHeight?: boolean;
    noScroll?: boolean;
}
interface HighlightedTextProps {
    cursor?: string;
}

export const BadgeDimension = '22px';

export const textEllipsis = css`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const VHPadding = css`
    padding: 16px 14px;
`;

export const heightFull = css`
    height: 100%;
`;

export const displayFlex = css`
    display: flex;
`;

export const flexDirectionColumn = css`
    ${displayFlex}
    flex-direction: column;
`;

export const GenericProfileDimension = css`
    width: ${secondaryProfileDimension};
    height: ${secondaryProfileDimension};
    object-fit: cover;

    @media (-webkit-min-device-pixel-ratio: 1.2) {
        width: calc(${secondaryProfileDimension} / 1.2);
        height: calc(${secondaryProfileDimension} / 1.2);
    }
`;

export const MainContainer = styled.div<Selected>`
    ${heightFull}
    width: 100%;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: ${(props: any) => (props.isViewChange ? '0' : '1rem 1.5rem')};
    background-color: ${(props) => props.theme.light_secondary.LIGHT_PRIMARY};
    box-shadow: inset 0 0 10px #eee;

    @media ${device.mobileL} {
        background-color: transparent;
    }
`;

export const AppContainer = styled.div`
    ${displayFlex}
    flex-direction: column;
    height: 100vh;

    .toaster-container {
        position: absolute;
    }

    @media ${device.mobileL} {
        background-color: transparent;
        background-image: url('/static/media/boma-best-bg.410491dcc137ccb9835f.png');
        background-position: center;
        background-size: cover;
        box-shadow: inset 0 0 0 2000px rgb(255 255 255 / 94%);
    }
`;

export const FlexRow = styled.div`
    ${displayFlex}
`;

export const FlexColumn = styled.div`
    ${displayFlex}
    ${flexDirectionColumn}

    .border-left {
        border-left: solid 2px ${(props: any) => props.theme.light_secondary.LIGHT_DARK};
        padding-left: 5px;

        @media ${device.laptop} {
            border: none;
            padding: 0;
        }
    }
`;

export const flexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const fontBold = css`
    font-weight: 700;
`;

export const fontRegular = css`
    font-weight: 400;
`;

export const fontMedium = css`
    font-weight: 500;
`;

export const FormContainer = styled.form`
    width: 100%;
    flex: 1;
    ${flexDirectionColumn}
    align-items: center;
    padding: 15px;

    @media ${device.mobileL} {
        padding: 10px;
    }
`;

export const FormPrimaryContainer = styled.div<FormPrimaryContainerProps>`
    padding: 1rem;
    background-color: ${(props) => props.theme.standard.WHITE};
    border: ${(props) =>
        props.border ? '' : '1px solid' + props.theme.light_primary.LIGHT_QUATERNARY};
    border-radius: 12px;
    flex: ${(props) => (props.noScroll ? 'initial' : 1)};
    display: flex;
    flex-direction: column;
    overflow: ${(props: any) => (props.noScroll ? 'initial' : 'auto')};
    box-shadow: 0px 8px 16px rgba(44, 40, 40, 0.08);
    position: relative;
    height: ${(props: any) => (props.cardFixedHeight ? '600px' : 'initial')};

    .form-container {
        overflow: auto;
        width: 100%;
    }

    @media ${device.mobileL} {
        overflow: unset;
        height: auto;
    }
`;

export const FormHeading = styled.p`
    color: ${(props) => props.theme.primary.SECONDARY_THEME};
    ${fontSize_LG}
    ${fontBold}
  margin-top: 20px;
    margin-bottom: 40px;
`;

export const FormSecondaryHeading = styled.p`
    color: ${(props) => props.theme.primary.SECONDARY_THEME};
    ${fontSize_BASE};
    ${fontBold};
`;

export const customSelectStylesSecondary = {
    container: (provided: any) => ({
        ...provided,
        flexBasis: 'auto'
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: state.isSelected ? 'white' : '',
        // background: state.isSelected ? 'red' : '',
        borderRadius: '4px',
        cursor: 'pointer',
        fontWeight: state.isSelected ? '500' : '400',
        padding: '4px 12px',
        margin: '4px 0',
        fontSize: size.sm
    }),
    menuPortal: (base: any, styles: any) => ({ ...base, ...styles, zIndex: 999 }),
    menu: (styles: any) => ({
        ...styles,
        border: '1px solid ' + theme.primary.THEME,
        color: '#5A5A5A',
        zIndex: 994
    }),
    menuList: (styles: any) => ({ ...styles, padding: '2px 5px' }),
    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: (styles: any) => ({
        ...styles,
        color: theme.light_primary.LIGHT_NONARY,
        fontWeight: 500,
        textTransform: 'capitalize',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '100%'
    }),
    control: (styles: any) => ({
        ...styles,
        boxShadow: '0px',
        cursor: 'pointer',
        border: '1px solid #B3B3B3',
        borderRadius: '6px',
        minHeight: '38px',
        '&:hover': { borderColor: theme.primary.THEME }
    }),
    dropdownIndicator: (dropdownStyles: any) => ({
        ...dropdownStyles,
        color: theme.standard.DARK_BLACK,
        padding: '0px 8px'
    }),
    valueContainer: (styles: any, state: any) => ({
        ...styles,
        position: 'relative',
        overflow: state.isDisabled ? 'hidden' : 'auto',
        maxHeight: '150px'
    }),
    singleValue: (providedStyles: any) => ({
        ...providedStyles,
        paddingRight: '5px',
        color: theme.standard.DARK_BLACK,
        fontWeight: 500,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
    }),
    multiValueLabel: (providedStyles: any) => ({
        ...providedStyles,
        width: '100%'
    })
};

export const HighlightedText = styled.span<HighlightedTextProps>`
    ${fontSize_SM}
    color: ${(props) => props.theme.info.DARK};
    cursor: ${(props: any) => props.cursor || ' pointer'};
`;

export const WarningText = styled.span`
    ${fontSize_SM}
    color: ${(props) => props.theme.danger.DARK_DANGER};
    cursor: pointer;
`;

export const InformationTagLine = styled.p`
    margin: 0;
    ${fontSize_BASE};
    ${fontRegular}
    color: ${(props) => props.theme.light_primary.LIGHT_DENARY};
`;

export const SuggestionText = styled.p`
    color: ${(props) => props.theme.light_primary.LIGHT_SENARY};
    ${fontSize_XS}
    ${fontRegular}
    margin: 0;
`;

export const ImageSuggestionText = styled.div`
    color: ${(props) => props.theme.light_primary.LIGHT_SENARY};
    ${fontSize_XS}
    ${fontRegular}
    p {
        margin: 0;
    }
`;

export const ButtonsList = styled.div`
    ${displayFlex}
    button {
        margin-left: 10px;
    }

    button:first-child {
        margin-left: 0px;
    }

    .toggle-collapse {
        background-color: ${(props) => props.theme.primary.SECONDARY_THEME};
        color: ${(props) => props.theme.standard.WHITE};
        border-radius: 100%;
        padding: 1em;
        width: 2em;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
`;

export const SuccessMessageHeading = styled.p`
    color: ${(props) => props.theme.info.DARK};
    ${fontSize_XXL}
    ${fontMedium}
`;

export const SuccessMessageContent = styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.light_primary.LIGHT_NONARY};
    text-align: center;
`;

export const DecorationText = styled.p`
    text-decoration: underline;
    ${fontSize_BASE}
    font-weight: 400;
    color: ${(props) => props.theme.light_primary.LIGHT_TERTIARY};
    cursor: pointer;
    &:hover {
        color: ${(props) => props.theme.primary.THEME};
    }
`;

export const DisclaimerText = styled.p`
    ${fontSize_SM}
    ${fontRegular}
`;

export const ContentText = styled(DisclaimerText)`
    color: ${(props) => props.theme.light_primary.LIGHT_NONARY};
`;

export const SCBtnGroup = styled.div`
    button {
        margin: 0.25rem;
    }

    i {
        padding-right: 0.5rem;
    }
`;

export const FontCloseIcon = styled.img`
    width: ${closeIconWidth};
    height: ${closeIconWidth};
    cursor: pointer;

    @media (-webkit-min-device-pixel-ratio: 1.2) {
        width: calc(${closeIconWidth} / 1.2);
        height: calc(${closeIconWidth} / 1.2);
    }
`;

export const FontImageTemplateIcon = styled.img`
    width: ${entryInputHeight};
    height: ${entryInputHeight};
    border-radius: 100%;
    object-fit: cover;
    cursor: pointer;

    @media (-webkit-min-device-pixel-ratio: 1.2) {
        width: calc(${entryInputHeight} / 1.2);
        height: calc(${entryInputHeight} / 1.2);
    }
`;

export const VideoIframeContainer = styled.div`
    ${flexDirectionColumn}
    flex: 1;

    iframe {
        width: 100%;
        flex: 1;
    }
`;
export const UserProfileSection = styled.div`
    ${displayFlex}
    padding: 1rem;
    color: ${(props) => props.theme.primary.SECONDARY_THEME};
    background-color: ${(props) => props.theme.light_secondary.LIGHT_PRIMARY};
    border: 1px solid ${(props) => props.theme.light_secondary.LIGHT_PRIMARY};
    border-radius: 0.75rem;
    align-items: center;
    letter-spacing: 0.7px;
    text-align: center;
    word-break: break-word;

    .profile-img {
        ${GenericProfileDimension};
        border-radius: 50%;
    }

    p {
        margin: 0;
        ${fontSize_SM};
        ${fontMedium};
    }

    b {
        padding: 0.5rem 0;
        ${fontSize_LG};
        ${fontBold};
    }
`;
export const ProfileLargeImage = styled.img`
    ${GenericProfileDimension};
    border-radius: 50%;
`;

export const LoginImage = styled.img`
    @media ${device.tablet} {
        width: 100%;
    }
`;

export const FormSecondaryContainer = styled.div`
    ${displayFlex}
    flex: 1;
    overflow: auto;
    padding: 1rem;
    color: ${(props) => props.theme.primary.SECONDARY_THEME};
    background-color: ${(props) => props.theme.light_secondary.LIGHT_PRIMARY};
    border: 1px solid ${(props) => props.theme.light_secondary.LIGHT_PRIMARY};
    border-radius: 0.75rem;
`;

export const ThemeColorFontIcon = styled.i`
    color: ${(props) => props.theme.standard.WHITE};
    ${fontSize_BASE}
    border: none;
    padding: 0;
    padding-right: 10px;
    cursor: pointer;
`;

export const WhiteColorFontIcon = styled(ThemeColorFontIcon)`
    color: ${(props) => props.theme.standard.WHITE};
`;

export const CrossICon = styled(ThemeColorFontIcon)<CrossIconProps>`
    color: ${(props) =>
        props.isColorDark ? props.theme.light_primary.LIGHT_SENARY : props.theme.standard.WHITE};
    ${fontSize_XL};

    @media ${device.tablet} {
        position: absolute;
        right: 0;
        top: 10px;
    }
`;

export const StickyBottom = styled.div`
    position: sticky;
    bottom: 0;
    padding: 1em 0;
    background-color: white;
    z-index: 9;
    padding-bottom: 0;
`;

export const HighlightedUnderline = styled.p`
    color: #5076ba;
    text-decoration: underline;
    letter-spacing: 0px;
    ${fontSize_SM};
    font-weight: 500;
    cursor: pointer;
`;

export const CustomCheckBox = styled.label<CheckboxProps>`
    display: block;
    position: relative;
    cursor: ${(props: CheckboxProps): string => (props.isDisabled ? 'not-allowed' : 'pointer')};
    margin: 2px 0px;
    color: ${(props: any) => (props.isTextPrimary ? `${theme.primary.THEME}` : '#424D69')};
    ${fontSize_SM};

    input {
        height: ${customCheckBoxDimension};
        width: ${customCheckBoxDimension};
    }

    input[type='checkbox'] {
        visibility: hidden;
    }

    .disclaimer-checkbox {
        visibility: visible !important;
    }

    .duplicate-checkbox {
        position: relative;
        top: 0;
        left: 0;
        height: ${customCheckBoxDimension};
        width: ${customCheckBoxDimension};
        border: 2px solid ${(props) => props.theme.light_primary.LIGHT_OCTONARY};
        background-color: ${(props: CheckboxProps): string =>
            props.isDisabled ? theme.light_secondary.LIGHT_PRIMARY : theme.standard.WHITE};
        border-radius: 4px;
        opacity: ${(props: CheckboxProps): string => (props.isDisabled ? '0.5' : '1')};
    }

    input:checked ~ .duplicate-checkbox {
        background-color: ${(props) => theme.primary.THEME};
        border: 1px solid ${(props) => props.theme.primary.THEME};
        opacity: 1;
    }

    .duplicate-checkbox:after {
        content: '';
        position: absolute;
        display: none;
    }

    input:checked ~ .duplicate-checkbox:after {
        display: block;
    }

    .duplicate-checkbox:after {
        left: 5px;
        bottom: 4px;
        width: 5px;
        height: 10px;
        border: solid ${(props) => theme.standard.WHITE};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
`;

export const BuildingImageContainer = styled.div`
    ${GenericProfileDimension};
    border-radius: 50%;
    position: relative;

    .profile-image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .edit-button-icon {
        position: absolute;
        width: 25px;
        height: 25px;
        right: 0;
        bottom: 10px;
    }
`;

export const Tab = styled.div<Selected>`
    margin: 5px 0px;
    min-height: ${tabItemHeight};
    border-radius: ${(props) => (props.isWebBooking ? tabItemHeight : '7px')};
    ${displayFlex};
    ${flexCenter};
    background-color: ${(props) => (props.isWebBooking ? props.theme.standard.WHITE : '#dddddd')};
    border: ${(props) => (props.isWebBooking ? '1px solid #D2D3D2' : 'none')};

    @media (-webkit-min-device-pixel-ratio: 1.2) {
        min-height: calc(${tabItemHeight} / 1.2);
    }

    @media ${device.tablet} {
        flex-direction: column;
        width: 100%;
    }
    @media ${device.mobileL} {
        flex-direction: column;
        width: 100%;
        border-radius: ${(props) => props.isWebBooking && '1rem'};
    }
`;

export const TabItem = styled.button<Selected>`
    border: none;
    height: ${tabItemHeight};
    min-width: ${(props) => (props.selected ? `${tabItemActiveWidth}` : `${tabItemInActiveWidth}`)};
    ${flexCenter};
    cursor: ${(props) => (props.Disabled ? `none` : `pointer`)};
    opacity: ${(props) => (props.Disabled ? `0.5` : `1`)};
    background-color: ${(props) =>
        props.selected
            ? props.isInform
                ? `#b2d235`
                : props.theme.primary.THEME
            : props.isWebBooking
            ? props.theme.standard.WHITE
            : '#dddddd'};
    color: ${(props) =>
        props.selected ? props.theme.standard.WHITE : props.theme.primary.SECONDARY_THEME};
    border-radius: ${(props) => (props.isWebBooking ? tabItemHeight : '6px')};
    ${fontMedium};
    ${fontSize_SM}
    padding: 0px 15px;

    @media (-webkit-min-device-pixel-ratio: 1.2) {
        height: calc(${tabItemHeight} / 1.2);
        min-width: calc(
            ${(props) => (props.selected ? `${tabItemActiveWidth}` : `${tabItemInActiveWidth}`)} /
                1.3
        );
        padding: 0px 13px;
        img {
            width: calc(${tabItemActiveWidth} / 4);
        }
    }

    @media ${device.tablet} {
        width: 100%;
        height: auto;
        padding: 6px 14px;
    }

    &:focus {
        outline: none;
    }
    &:focus-visible {
        z-index: 99;
        border: 2px solid ${(props) => props.theme.primary.focusButtonColor};
        outline: none;
    }

    &:hover {
        background-color: ${(props) =>
            props.selected
                ? props.theme.info.DARK_INFO
                : props.theme.light_secondary.LIGHT_SECONDARY};
    }
`;

// secondary react-collapse
export const SecondaryReactCollapse = styled.div<Selected>`
    border: solid 1px #dedede;
    border-left: solid 5px ${(props: any) => props.theme.primary.THEME};
    border-radius: 6px;

    .ReactCollapse--collapse {
        transition: height 100ms;
        height: auto;
    }

    .collapse-content {
        padding-top: 0;

        .row {
            background-color: white;
            padding-top: 10px;
            padding-bottom: 10px;
            ${fontSize_SM}
        }

        .row:nth-child(2) {
            background-color: #f8fafd;
        }
    }
`;

export const SecondaryCollapseItem = styled.div<Selected>`
    cursor: pointer;
`;

export const HeadingLogo = styled.img`
    width: 250px;
    padding-bottom: 12px;
`;

export const Partition = styled.div<Selected>`
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    @media ${device.mobileL} {
        flex-direction: column;
        border-right: none;
    }

    .left-wrap {
        border-right: ${(props: any) =>
            props.isStepperUI
                ? 'none'
                : `2px solid
      ${theme.light_primary.LIGHT_PRIMARY}`};
        flex: 1;
        overflow: auto;
        max-width: ${(props: any) => (props.isStepperUI ? '224px' : '280px')};
        background-color: ${(props: any) => (props.isStepperUI ? '#F7F7F7' : 'transparent')};

        .section-separate {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            .btn-bottom {
                color: #4b74be;
                border-top: solid 1px #ccc;
                display: flex;
                align-items: center;
                padding: 10px 14px;
                cursor: pointer;

                &:hover {
                    background-color: rgb(238 238 238 / 28%);
                }
            }
        }

        @media ${device.tablet} {
            max-width: 100%;
        }
    }

    .right-wrap {
        flex: 3;
        overflow: auto;
        ${VHPadding};

        ol {
            padding-left: 20px;
            li {
                padding: 5px 0;
                font-weight: 700;
                ${fontSize_SM};
            }
        }

        .light {
            color: ${(props: any) => props.theme.light_primary.LIGHT_SECONDARY};
            ${fontSize_SM};
        }
    }

    .list-bottom-border {
        ol {
            li {
                padding: 15px 0;
                border-bottom: 1px solid #b2c3d9;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .left-wrap,
    .right-wrap {
        height: 100%;

        @media ${device.mobileL} {
            height: auto;
            width: 100%;
            border-right: none;
        }
    }
`;

export const PermissionLeftPartition = styled.div`
    display: flex;
    flex: 1;
    border-right: ${(props: any) =>
        props.isStepperUI
            ? 'none'
            : `2px solid
      ${theme.light_primary.LIGHT_PRIMARY}`};
`;

export const CollapseContainer = styled.div<CollapseProps>`
    width: 100%;
    background-color: ${(props) => props.theme.standard.WHITE};
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 8px;
    border: 1px solid ${(props: any) => (props.isOpen ? '#BAC2D0' : '#BAC2D0')};
    margin-bottom: 15px;

    .instruction-header {
        padding: 12px;
        background-color: ${(props) => props.theme.light_secondary.LIGHT_PRIMARY};
    }
`;

export const CollapsedItem = styled.div<CollapseProps>`
    margin-bottom: 0rem;

    .sub-menu {
        background-color: ${(props) => props.theme.light_secondary.LIGHT_PRIMARY};
    }

    .left {
        flex: 1;
    }

    .right {
        flex: 2;

        @media ${device.mobileL} {
            flex: auto;
            border-top: solid 1px #eee;
        }
    }

    .custom-questions-wrapper {
        ol {
            li {
                display: inline-flex;
                flex-direction: column;

                p {
                    padding: 0;
                }
            }
        }
    }

    .main-item {
        background-color: ${(props) => props.theme.light_secondary.LIGHT_DARK};
    }

    .active {
        .header {
            border-bottom: 5px solid red;
            padding-bottom: 10px;
        }
    }

    .ReactCollapse--collapse {
        transition: height 150ms;
        height: auto;
    }

    .collapsearrow {
        cursor: pointer;
        color: #575b64;
    }

    .status {
        color: ${(props: any) => props.theme.danger.DARK_DANGER};
        margin-left: 25px;
        cursor: inherit;
    }

    p {
        ${fontSize_SM};
        color: ${(props: any) => props.theme.primary.THEME};
        margin: 0;
        padding: 5px 15px;
        cursor: pointer;

        &:first-child {
            color: ${(props: any) => props.theme.light_primary.LIGHT_SECONDARY};
            cursor: inherit;

            b {
                color: ${(props: any) => props.theme.primary.SECONDARY_THEME};
            }
        }

        &.user-description, 
        &.user-description * {
           color: inherit;
        }

    .cursor-pointer {
        cursor: pointer;
    }
    .remove-button {
        ${flexCenter}
    }

    @media ${device.mobileL} {
        .left,
        .right {
            padding: 10px 0;
        }

        p {
            padding: 0 5px;
        }
    }
`;

export const InfoIconImage = styled.img`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

export const BtnEdit = styled.div`
    background-color: #f6f6f6;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    ${fontSize_SM};
    padding: 3px 12px;
    cursor: pointer;
    font-weight: 400;

    p {
        color: #62ada1;
    }
`;

export const Details = styled.div`
    padding: 1rem;
    border: solid 1px ${(props: any) => props.theme.primary.THEME};
    border-left: solid 5px ${(props: any) => props.theme.primary.THEME};
    margin: 16px;
    padding-left: 14px;
    border-radius: 5px 10px 10px 5px;

    h2 {
        color: #333b4b;
        ${fontSize_BASE};
        padding: 10px 0;
    }

    .highlight {
        color: ${(props: any) => props.theme.primary.THEME};
        cursor: pointer;
    }

    .content {
        .each-item {
            padding: 10px 0;
            align-items: center;

            h3,
            p {
                margin-bottom: 0;
                ${fontSize_SM};
            }

            h3 {
                color: #333b4b;
            }

            p {
                color: ${(props) => props.theme.primary.SECONDARY_THEME};
            }
        }
    }

    .fa-close {
        color: ${(props) => props.theme.light_primary.LIGHT_SECONDARY};
        cursor: pointer;
    }

    .footer-row {
        border-top: solid 1px #ccc;
        padding-top: 15px;

        .left > div:first-child {
            padding-left: 0;
        }

        .left > div:last-child {
            border-right: none;
        }

        .left > div {
            border-right: solid 1px #ccc;
            padding-right: 16px;
            padding-left: 16px;
        }
    }
`;

export const ItemHeading = styled.b`
    color: ${(props) => props.theme.primary.SECONDARY_THEME};
    ${fontSize_SM};
    font-weight: 700;
    flex: 1;
    display: flex !important;
    align-items: center;
    ${textEllipsis};

    img {
        padding-right: 0.5rem;
    }
`;

export const BenchmarkingItemHeading = styled.b`
    color: ${(props) => props.theme.primary.SECONDARY_THEME};
    ${fontSize_SM};
    font-weight: 500;
    flex: 1;
    display: flex !important;
    align-items: center;
    ${textEllipsis};

    img {
        padding-right: 0.5rem;
    }
`;

export const GoBack = styled.div`
    padding-bottom: 15px;
    cursor: pointer;
    color: ${(props: any) => props.theme.primary.SECONDARY_THEME};
    font-weight: 600;
    ${fontSize_BASE};
    display: flex-fill;
`;

/* dotted design for file upload */
export const DottedUploadContainer = styled.div`
    border: dotted 2px #ccc;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 8px;
`;

/* edit individual image on edit certification level */
export const EditImageContainer = styled.div<Selected>`
    border-radius: 100%;
    border: ${(props) => (props.isBorder ? '1px solid #D2D3D2' : 'none')};
    background-color: #eee;
    padding: 20px;
    width: 160px;
    height: 160px;
    ${flexCenter};
    position: relative;

    .edit-action-absolute {
        position: absolute;
        bottom: 0;
        right: 10px;
        background-color: #62ada1;
        border-radius: 100%;
        border: solid 1px white;

        img {
            padding: 10px;
        }
    }
`;

/* secondary navigation tab design syles */
export const List = styled.ul<Selected>`
    list-style: none;
    padding-left: 0px;
    ${VHPadding};

    @media ${device.mobileL} {
        display: flex;
    }
`;

export const ActiveItem = styled.li<Selected>`
    ${fontSize_SM};
    color: ${(props: any) => (props.selected ? '#4A7EDB' : '#626262')};
    margin-bottom: 10px;
    cursor: pointer;
    background-color: ${(props: any) => (!props.selected ? 'none' : '#DFE7F7')};
    padding: 10px;
    border-radius: 8px;
    font-weight: 500;

    &:hover {
        background-color: #dfe7f7;
        color: #4a7edb;
        opacity: 0.9;
    }
`;

export const Badge = styled.div<BadgeProps>`
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    background-color: ${(props: any) =>
        props.isSuccess ? props.theme.success.DARK_SUCCESS : props.theme.danger.DARK_DANGER};
    color: ${(props) => props.theme.standard.WHITE};
    width: 65px !important;
`;

export const QuestionWrap = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px #ccc;
    padding: 8px 15px;

    &:last-child {
        border-bottom: none;
    }

    .question {
        ${fontSize_SM};
        font-weight: 600;
        display: flex;
        align-items: start;
    }

    .answer {
        display: flex;
        padding-top: 12px;
        ${fontSize_SM};
        font-weight: 400;
        position: relative;
        left: 5px;
    }

    .flag {
        cursor: pointer;
    }

    .transition {
        transform: scale(1.5);
    }

    .client-note-wrapper,
    .internal-comment-wrapper {
        color: ${(props: any) => props.theme.primary.THEME};
        ${fontSize_SM};
        font-weight: 600;
        cursor: pointer;
    }

    .client-note-wrapper-disabled,
    .internal-comment-wrapper-disabled {
        color: #808080;
        ${fontSize_SM};
        font-weight: 600;
        cursor: pointer;
        pointer-events: none;
    }

    .attach-files {
        text-decoration: underline;
        position: relative;
        left: 8px;

        p {
            color: ${(props: any) => props.theme.primary.THEME};
            margin-top: 4px;
            padding: 0;
            width: max-content;
        }

        b,
        b:hover {
            color: ${(props: any) => props.theme.primary.THEME};
            ${fontSize_SM};
            cursor: pointer;
            font-weight: 600;
        }
    }

    .attach-files-disabled {
        text-decoration: underline;
        position: relative;
        left: 8px;

        b,
        b:hover {
            color: #808080;
            ${fontSize_SM};
            cursor: pointer;
            pointer-events: none;
            font-weight: 600;
        }
    }
`;

export const CollapseNavigationItem = styled.div`
    cursor: pointer;
    text-align: left;
    letter-spacing: 0px;
    color: #626262;
    opacity: 1;
    font-weight: 500;
    ${fontSize_SM};

    .active {
        background-color: #dfe7f7;
        border-radius: 8px;
        color: #4a7edb;
    }

    .active,
    .noactive {
        padding: 5px 12px;
    }

    .ReactCollapse--collapse {
        transition: height 100ms;
        height: auto;
    }

    .collapsearrow {
        cursor: pointer;
        color: #575b64;
    }
`;

export const SubFolderList = styled.ul`
    list-style: none;
    color: #626262;

    li {
        padding: 12px 0;

        &:first-child {
            padding-top: 20px;
        }

        &:hover {
            color: ${(props: any) => props.theme.primary.THEME};
        }
    }
`;

export const textNormal = css`
    ${fontSize_SM}
    font-weight: normal;
    letter-spacing: 0.28px;
    color: ${theme.light_primary.LIGHT_QUINARY};

    @media (-webkit-min-device-pixel-ratio: 1.2) {
        ${fontSize_XS};
    }
`;

export const BtnStickyRight = styled.div`
    position: absolute;
    right: 0;
    top: 20%;

    button {
        margin: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 40px;
        height: 40px;

        img {
            margin-right: 0;
            height: 18px;
            width: 18px;
        }
    }
`;

export const QuestionnaireTextLabels = styled.label`
    margin: 0;
    ${fontSize_SM};
    ${fontMedium};
    color: #424d69;
    padding: 0px 10px 0 0;
`;

export const ProfileIconLoader = styled.i`
    padding: 5px;
    color: ${(props: any) => props.theme.primary.color};
`;

export const ProfileSection = styled.div`
    .small-profile-icon {
        width: ${userProfileIconWidth};
        height: ${userProfileIconWidth};
    }
    .header-profile-icon {
        width: calc(${userProfileIconWidth} / 1.2);
        height: calc(${userProfileIconWidth} / 1.2);
    }

    .small-profile-icon,
    .header-profile-icon {
        object-fit: cover;
        border-radius: 50%;
    }
`;

export const TopHeaderBar = styled.div`
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: end;
    align-items: center;
    background: ${theme.primary.SECONDARY_THEME};

    .logo {
        img {
            max-height: 36px;
        }
    }

    .dropdown-name {
        color: ${theme.standard.WHITE};
        cursor: pointer;
    }

    .section-bell-badge {
        cursor: pointer;
        position: relative;
        top: 4px;

        i {
            color: white;
            ${fontSize_XXXL};
            margin-right: 12px;
        }

        .badge {
            position: absolute;
            display: flex;
            bottom: 10px;

            p {
                background-color: orange;
                border-radius: 100%;
                width: ${BadgeDimension};
                height: ${BadgeDimension};
                display: flex;
                justify-content: center;
                align-items: center;
                color: black;
                border: solid 1px black;
                ${fontSize_XXS};
                padding: 0.1rem;
                margin: 0;
            }
        }
    }
`;
