import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { setUserLanguage, validateYupSchema } from '../CommonUtilities';
import { constantStrings } from '../constantStrings';
import { getMultiTenantConfig } from '../Store/actions';
import FormSelect from './DesignSystem/FormElements/FormSelect';
import { useEffect, useMemo } from 'react';

const SelectLanguageComponent = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userInfo = useSelector((state: any) => state.CurrentUser.currentUserData);

    const languageOptions = useMemo(
        () => [
            { value: 'en', label: t(constantStrings.english) },
            { value: 'fr', label: t(constantStrings.french) },
            { value: 'es', label: t(constantStrings.spanish) },
            { value: 'zh-hans', label: t(constantStrings.simplifiedChinese) }
        ],
        [t]
    );

    const schema = yup.object().shape({
        language: validateYupSchema('select', t(constantStrings.language), false)
    });
    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: { language: { value: 'en', label: t(constantStrings.english) } }
    });

    const { setValue } = methods;
    useEffect(() => {
        if (userInfo) {
            setValue(
                'language',
                languageOptions.find((lang) => lang.value === userInfo.language) ||
                    languageOptions[0]
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo, setValue]);

    const handleLanguageChange = (userLanguage: string) => {
        localStorage.setItem('userLanguage', userLanguage);
        setUserLanguage(userLanguage);
        dispatch(getMultiTenantConfig());
    };

    return (
        <FormProvider {...methods}>
            <form className={props.className || 'col-4'}>
                <FormSelect
                    options={languageOptions}
                    displayName={t(constantStrings.selectApplicationLanguage)}
                    displayNameColor={props.displayNameColor}
                    name={'language'}
                    placeholder={t(constantStrings.selectlanguage)}
                    asterisk={false}
                    onChangeHandler={(lang: any) => handleLanguageChange(lang.value)}
                />
            </form>
        </FormProvider>
    );
};

export default SelectLanguageComponent;
