import loadable from '@loadable/component';
import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { customSelectStylesSecondary, FlexColumn } from '../../styles';
import {
    CustomThemeColor,
    DropdownIndicator,
    Menu,
    MultiValue,
    Option,
    Placeholder,
    SingleValue
} from './ReactSelectHelpers';
import { ErrorText, LabelHeading, SelectContainer } from './styles';
import { useTranslation } from 'react-i18next';
import { constantStrings } from '../../../constantStrings';

const Tooltip = loadable(() => import('../Tooltip/index'));
export interface Options {
    value: string | number | boolean;
    label: string;
}

interface SelectHookProps {
    options: Array<Options>;
    displayName: string;
    displayNameColor?: string;
    name: any;
    placeholder?: string | null;
    menuPortalTarget?: HTMLElement | null;
    asterisk?: boolean;
    infoIcon?: boolean;
    isDisabled?: boolean;
    className?: string;
    isClearable?: boolean;
    styles?: any;
    maxHeight?: string;
    noMargin?: boolean;
    infoText?: string | null;
    menuShouldBlockScroll?: boolean;
    openMenuOnClick?: boolean;
    onMenuScrollToBottom?: (props: any) => void;
    isMulti?: boolean;
    defValue?: any;
    onChangeHandler?: any;
    linkURL?: any;
    linkURLText?: any;
    totalCount?: number;
    actualCount?: number;
    getNextSetOfData?: ({
        offset,
        search,
        shouldAppend
    }: {
        offset?: number;
        search?: string;
        shouldAppend: boolean;
    }) => void;
    enableSelectAll?: boolean;
    selectedOptionsLimit?: number;
    maxLimitMsg?: string;
    isLoading?: boolean;
}

const FormSelect: FunctionComponent<SelectHookProps> = ({
    displayName,
    displayNameColor,
    infoText,
    name,
    options,
    placeholder,
    styles,
    asterisk = true,
    infoIcon,
    className = '',
    isClearable,
    isDisabled,
    noMargin = false,
    openMenuOnClick = true,
    menuPortalTarget = null,
    menuShouldBlockScroll = false,
    isMulti = false,
    defValue,
    onChangeHandler,
    linkURL,
    linkURLText,
    totalCount = 0,
    actualCount = 0,
    maxHeight = '',
    getNextSetOfData,
    enableSelectAll,
    selectedOptionsLimit,
    maxLimitMsg,
    isLoading
}) => {
    const {
        formState: { errors },
        control
    } = useFormContext();
    const { t } = useTranslation();
    const selectAllOption = [{ value: 'Select All', label: t(constantStrings.selectAll) }];
    const optionsData = options
        ? enableSelectAll
            ? [...selectAllOption, ...options]
            : options
        : [];

    const onMenuScrollToBottom = () => {
        if (actualCount < totalCount)
            return (
                getNextSetOfData && getNextSetOfData({ offset: options.length, shouldAppend: true })
            );
    };

    const handleSearch = (inputValue: string) => {
        return (
            getNextSetOfData &&
            getNextSetOfData({
                search: inputValue,
                shouldAppend: false
            })
        );
    };

    const handleChange = (optionsData: Array<Options>, actionData: any, onChange: any) => {
        if (enableSelectAll) {
            let selectedOptions: Array<Options> = [];
            const { option, action } = actionData;
            if (option?.value !== 'Select All' && action === 'select-option') {
                selectedOptions = [...optionsData.filter((opt) => opt.value !== 'Select All')];
            } else if (option?.value === 'Select All' && action === 'select-option') {
                selectedOptions = [...optionsData.filter((opt) => opt.value === 'Select All')];
            } else {
                selectedOptions = optionsData;
            }

            onChange(selectedOptions);
            if (onChangeHandler) {
                onChangeHandler(selectedOptions);
            }
        } else {
            onChange(optionsData);
            if (onChangeHandler) {
                onChangeHandler(optionsData);
            }
        }
    };

    return (
        <FlexColumn className={className + ' px-2 ' + (noMargin ? ' ' : ' mb-3 ')}>
            {displayName && (
                <LabelHeading variant={'primary'} className={'mb-1'} color={displayNameColor}>
                    {displayName}
                    {asterisk ? <span className='text-danger'> * </span> : ''}
                    {infoIcon ? (
                        <>
                            <i
                                className='fas fa-info-circle ms-1'
                                data-tip
                                data-for={name}
                                data-event='click'
                                aria-hidden='true'
                            ></i>
                            <Tooltip id={name} place={'top'}>
                                <div>
                                    {infoText}
                                    {linkURL && (
                                        <a href={linkURL} target={'_blank'} rel={'noreferrer'}>
                                            {' '}
                                            {linkURLText || t(constantStrings.clickHere)}
                                        </a>
                                    )}
                                </div>
                            </Tooltip>
                        </>
                    ) : (
                        ''
                    )}
                </LabelHeading>
            )}
            <SelectContainer
                isEmpty={errors[name] ? true : false}
                maxHeight={maxHeight}
                // className={noMargin ? '' : 'mb-2'}
            >
                <Controller
                    control={control}
                    name={name}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                            onChange={(value: any, action: any) =>
                                handleChange(value, action, onChange)
                            }
                            options={optionsData}
                            components={{
                                Option,
                                SingleValue,
                                MultiValue: (props) => (
                                    <>
                                        <MultiValue
                                            {...props}
                                            customProps={{
                                                showMaxLimitMsg:
                                                    selectedOptionsLimit &&
                                                    value?.length >= selectedOptionsLimit,
                                                maxLimitMsg,
                                                selectedOptionsLimit
                                            }}
                                        />
                                    </>
                                ),
                                Placeholder,
                                DropdownIndicator: openMenuOnClick ? DropdownIndicator : () => null,
                                Menu
                            }}
                            styles={styles || customSelectStylesSecondary}
                            value={defValue ? defValue : value}
                            placeholder={placeholder}
                            isClearable={isClearable}
                            theme={CustomThemeColor}
                            classNamePrefix={'react-select'}
                            menuPortalTarget={menuPortalTarget}
                            noOptionsMessage={() => t(constantStrings.noOptionsFound)}
                            onMenuScrollToBottom={onMenuScrollToBottom}
                            menuShouldBlockScroll={menuShouldBlockScroll}
                            menuPlacement={'auto'}
                            openMenuOnClick={openMenuOnClick}
                            onBlur={onBlur}
                            isMulti={isMulti}
                            ref={ref}
                            isDisabled={isDisabled}
                            onInputChange={handleSearch}
                            isOptionDisabled={(): any =>
                                selectedOptionsLimit && value?.length >= selectedOptionsLimit
                            }
                            isLoading={isLoading}
                        />
                    )}
                />
            </SelectContainer>
            {errors[name] && <ErrorText>{errors[name]?.message}</ErrorText>}
        </FlexColumn>
    );
};
export default FormSelect;
