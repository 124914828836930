export const constantStrings: Record<string, string> = {
    mustGreaterOrEqualTo: 'must be greater than or equal to',
    welcome: 'Welcome',
    helloWorld: 'Hello World',
    answersUpadted: 'Answers updated successfully',
    siteVisitScheduled: 'Site visit scheduled successfully',
    verifierAssignedSuccess: 'Verifier assigned successfully',
    attachmentAdded: 'Attachment added successfully',
    questionnaireMovedToArchive: 'Questionnaire has been moved to archive folder',
    move: 'Move',
    questionnaireMovedToLatest: 'Questionnaire has been moved to latest Successfully',
    moveToLatest: 'Move to Latest',
    draftCreated: 'Draft has been created',
    questionnaireSubmittedForVerification: 'Questionnaire submitted for verification successfully',
    newQuestionnairePublished: 'New questionnaire published successfully',
    passwordUpdated: 'Password updated successfully',
    loginSuccess: `Short Break for Maintenance - We're upgrading our Hub to improve your experience. Please check back shortly`,
    invoiceFeeUpdated: 'Invoice fee details updated successfully',
    invoiceFeeCreated: 'Invoice fee created successfully',
    fileDeleted: 'File deleted successfully',
    attachmentDeleted: 'Attachment deleted successfully',
    fileRenamed: 'File renamed successfully',
    fileUploaded: 'File uploaded successfully',
    fileAlreadyExists: 'file already exists',
    userCreated: 'User created successfully',
    userSignUp: 'User sign up successful',
    companyRegistrationSuccess: 'Company registration successful',
    localAssociationUpdated: 'Local Association updated successfully',
    localAssociationAdded: 'Local Association added successfully',
    countryUpdated: 'Country updated successfully',
    countryAdded: 'Country added successfully',
    certificationdataEdited: 'Certification level data edited successfully',
    certificationdataAdded: 'Certification level data added successfully',
    certificationTypeAdded: 'Certification type added successfully',
    keyWordEdited: 'Keyword edited successfully',
    keyWordCreated: 'Keyword created successfully',
    topicEdited: 'Topic edited successfully',
    topicCreated: 'Topic created successfully',
    focusAreaEdited: 'Focus Area edited successfully',
    focusAreaCreated: 'Focus Area created successfully',
    certifiedLevelRemoved: 'Certified level has been removed',
    certifiedLevelAssigned: 'Certified level has been assigned',
    furtherInformation: 'For further information, you may write to info@bomabest.org',
    information: 'Information',
    requestShared:
        'Your request has been shared with the BOMA Administrative team. They will review your request and grant permission.',
    congratulations: 'Congratulations!',
    buildingAdded: 'Building added successfully',
    editedSuccessfully: 'Edited successfully',
    editedDeliveryData: 'Edited delivery data',
    addedDeliveryData: 'Added delivery data',
    updateMeterDetails: 'Updated meter details',
    meterDataAdded: 'Meter data added successfully',
    wasteMeterAdded: 'Waste meter added successfully',
    meterAdded: 'Meter added successfully',
    energyStarEdited: 'ENERGY STAR property edited successfully',
    energyStarAdded: 'Added ENERGY STAR property successfully',
    companyAdded: 'Company added successfully',
    looksLikeOffline: 'Looks like you are offline, make sure you are online',
    primaryStatusInfoText:
        'Select Non-Confidential, if you approve to share your building name and information to be displayed publicly for reporting purpose.\nSelect Confidential, if you approve to share your building information in anonymized data format.',
    singleStreamInfoText:
        'Select the building certificate(s) for which you want to get certified under Single Stream Program. To know more about Single Stream Program and policies, ',
    portfolioStreamInfoText:
        'Select the building certificate(s) for which you want to get certified under Portfolio Stream Program. To know more about Portfolio Stream Program and policies,',
    numberOfUnitsInfoText:
        'Add number of units if building type selected is Multi Unit Residential Buildings.',
    infoText:
        'Select the Local Association best suited in this case. If you do not know which local association to select, ',
    buildingNameInfoText:
        'Buildings are only eligible to be assessed under one questionnaire if they meet the',
    buildingTypeInfoText: 'For details on eligible building types please visit our webpage',
    aboutAssetClasses: 'About Asset Classes',
    scheduleSiteVisit: 'Schedule Site Visit',
    healthCare: 'Healthcare',
    onSiteVerificationDateOptions:
        'Select on-site verification date options (select atleast two dates)',
    or: 'OR',
    viewCalender: 'View Calender',
    invitationSent: 'Invitation sent successfully',
    secondaryVerificationSaved: 'Secondary verification saved successfully.',
    secondaryVerificationCompleted: 'You have completed secondary verification',
    secondaryVerificationResponse:
        'Are you sure you want to submit your Secondary Verification response?',
    moveToLatestResponse:
        'You will lose the current questionnaire data, so are you sure you want to switch to the most recent version?',
    primaryVerificationSaved: 'Primary verification saved successfully.',
    startOnSiteVerification:
        'Once submitted, you will not be able to add any further comments till you start on-site verification.',
    preverificationResponse: 'Are you sure you want to submit your pre-verification response?',
    preSiteVerificationCompleted: 'You have completed pre-site verification',
    buildingManagementCompany: 'Building Management Company',
    buildingManagedBy: 'Building Managed By',
    enterName: 'Enter Name',
    facilityTourProvided: 'Facility Tour Provided By',
    noVerificationQuesFound: 'No Verification Question Found',
    onSite: 'On-site',
    saveExit: 'Save and Exit',
    submitCertification: 'Submit For Certification',
    submitForSecondaryVerification: 'Submit For Secondary Verification',
    questionnaireSubmittedForSecondaryVerification:
        'Questionnaire submitted for  secondary verification successfully',
    yesSubmit: 'Yes, Submit',
    yesPublish: 'Yes, Publish',
    yesDelete: 'Yes, Delete',
    chooseDate: 'Choose a Date',
    cannotChangeResponse: 'Once submitted, you will not be able to change your response.',
    answersSubmitted: 'Answers submitted successfully',
    onSiteVerification: 'On-Site Verification',
    preSiteVerification: 'Pre-Site Verification',
    noStartDataFound: 'No Start Verification Data Found',
    startSecondaryVerification: 'Start Secondary Verification',
    secondaryVerificationInProgress: 'Secondary Verification In-Progress',
    secondaryVerificationPending: 'Secondary Verification Pending',
    onSiteinProgress: 'On-Site In Progress',
    preSiteCompleted: 'Pre-Site Completed',
    preSiteInProgress: 'Pre-Site In Progress',
    verifierAssigned: 'Verifier Assigned',
    verificationDuration: 'Verification Duration (in days)',
    failVerification: 'Fail Verification',
    preSiteCompletionDate: 'Pre-Site Completion Date',
    onSiteCompletionDate: 'On-Site Completion Date',
    onSiteScheduleDate: 'On-Site Schedule Date',
    assign: 'Assign',
    assignForVerification: 'Assign for Verification',
    noPropertyUserFound: 'No Property User Found',
    selectSecondaryVerifier: 'Select Secondary Verifier',
    selectSecondaryVerifierCompany: 'Select Secondary Verifier Company',
    addSecondaryVerifier: 'Add Secondary Verifier',
    selectPrimaryVerifier: 'Select Primary Verifier',
    secondaryVerifier: 'Secondary Verifier',
    primaryVerifier: 'Primary Verifier',
    primaryVerifierCompany: 'Primary Verifier Company',
    secondaryVerifierCompany: 'Secondary Verifier Company',
    assignVerifier: 'Assign Verifier',
    noVerificationDataFound: 'No Verification data found',
    revert: 'Revert',
    reverted: 'Reverted',
    revertedFrom: 'Reverted From',
    revertedDate: 'Reverted Date',
    revertedCount: 'Reverted Count',
    revertVerification: 'Revert Verification',
    verificationID: 'Verification ID',
    verificationReverted: 'Verification reverted successfully',
    uploadUsers: 'Upload Users',
    exportUsers: 'Export Users',
    viewUsers: 'View Users',
    disabled: 'Disabled',
    mobileNumber: 'Mobile Number',
    companyNotInList: 'If you don’t see your company in the list, click here to add it.',
    userExists: 'User already exists',
    addUser: 'Add User',
    passwordResetSuccess: 'Password reset successfully',
    newBuildingAccess: 'Adding New Building Access',
    removeAccess: 'Remove Access',
    remove: 'Remove',
    addBuildings: 'Add Buildings',
    buildingAccessRemoved: 'Building access has been removed',
    viewBuildings: 'View Buildings',
    buildingsAssociatedUser: 'Buildings associated with this user',
    enterPrimaryCompanyName: 'Enter primary company name',
    accessAccount: 'access their account until the account is enabled',
    onceDisableduserCannot: 'Once disabled, the user cannot',
    DisableTheUser: 'Are you sure you want to disable the user?',
    enableTheUser: 'Do you want to enable the user?',
    userProfileUpdated: 'User profile updated successfully',
    noBuildingDataFound: 'No Building Data Found',
    buildingAccessAdded: 'Building access has been added',
    logoAddedBuildingCertificate: 'This logo will be added to your building certificate.',
    uploadCompanyImage: 'Upload Company Image',
    newCompanyRegistration: 'New Company Registration',
    newCompany: 'New Company',
    addCompany: 'Add Company',
    uploadCompanies: 'Upload Companies',
    searchCompanies: 'Search Companies',
    noCompanyFound: 'No Company Found',
    certificationCount: 'Certification Count',
    registeredBuildings: 'Registered Buildings',
    companyID: 'Company ID',
    editCompanyDetails: 'Edit Company Details',
    companyList: 'Company List',
    companyDetails: 'Company Details',
    company: 'Company',
    instructionsToAddLogo: 'Follow the image instructions to add your company logo.',
    companyDetailsUpdated: 'Company details updated successfully',
    remarks: 'Remarks',
    newCompanyType: 'New Company Type',
    oldCompanyType: 'Old Company Type',
    companyVerifierRemarks:
        'Users will not be verifier for any building and they will not have access to any building. Company user’s user group shall change to Property Users.',
    propertyManagementOwnerConsultant: 'Property Management/Property Owner/External Consultant',
    externalConsultantVerifier: 'External Consultant/Verifier',
    propertyOwnerManagement: 'Property Owner/Property Management',
    propertyManagementOwner: 'Property Management/Property Owner',
    video: 'video',
    Select: 'Select',
    selectCountry: 'Select Country',
    enterCompanyMail: 'Enter company mail',
    userRegistration: 'User Registration',
    loginCredentials: 'Please login with new credentials.',
    passwordChanged: 'Password successfully changed.',
    resetPassword: 'Reset Password',
    passwordMust: 'Password must',
    passwordReset: 'Password Reset',
    signUp: 'Sign up',
    noAccount: "Don't have an account?",
    incorrectUserNamePassword: 'Incorrect username or password. Kindly enter correct credentials.',
    password: 'Password',
    passwordResetLinkSuccess: 'Password reset link successfully sent to your registered email',
    back: 'Back',
    passwordResetLink: 'Enter your registered email and a password reset link will be sent.',
    signupPage: 'Signup Page',
    successfullyRegisteredCompany: 'You have successfully registered your company on BOMA BEST.',
    companyRegistration: 'Company Registration',
    updatedBy: 'Updated by',
    addedOn: 'Added on',
    lastUpdated: 'Last updated on',
    lastEditedOn: 'Last Edited on',
    invoiceConfiguration: 'Invoice Configuration',
    adminVerification: 'Admin Verification',
    startVerification: 'Start Verification',
    assignVerification: 'Assign Verification',
    extendVerification: 'Extend Verification',
    extend: 'Extend',
    verificationExtended: 'Verification Extended successfully',
    completedVerifications: 'Completed Verifications',
    companies: 'Companies',
    users: 'Users',
    userManagement: 'User Management',
    portfolio: 'Portfolio',
    properties: 'Properties',
    allNotifications: "That's all your notifications from the last 30 days.",
    ago: 'ago',
    selectNotificationType: 'Select Notification Type',
    markAllAsRead: 'Mark all as read',
    onlyShowUnread: 'Only show unread',
    showMore: 'Show more',
    showLess: 'Show less',
    notificationType: 'Notification Type',
    logOut: 'Log Out',
    logoutSuccess: 'Logout successful',
    goToProfile: 'Go to Profile',
    updateYourProfileDetails: 'Update your profile details to get started on adding buildings.',
    hello: 'Hello!',
    terms: 'Terms',
    privacy: 'Privacy',
    copyrightRightsReserved: 'Copyright © 2021-2022 BOMA Best All rights reserved.',
    translationsAvailable: 'Translations available in:',
    login: 'Login',
    registrationConfirmation:
        'You have successfully registered. Please check your inbox or spam folder for registration confirmation.',
    forgotPassword: 'Forgot Password',
    canada1: 'Canada +1',
    seeCompanyInTheList: "If you don't see your company in the list, ",
    enterRegisteredMailAndPassword:
        'Enter your registered email and password reset link will be sent. ',
    oldPasswordHere: '<Old Password Here>',
    enterOldPassword: 'Enter Old Password',
    loginPage: 'Login Page',
    makingCommercialRealEstate:
        'Making Commercial Real Estate More Sustainable, Smarter, Healthier',
    canada: 'Canada',
    selectlanguage: 'Select language',
    selectApplicationLanguage: 'Select Application Language',
    simplifiedChinese: 'Simplified Chinese',
    spanish: 'Spanish',
    bomaBest: 'BOMA BEST',
    french: 'French',
    english: 'English',
    noPermissionAction: 'You have no permission to perform any action',
    moreInformationContact: 'For more information, please contact',
    showPassword: 'Show Password',
    confirmNewPassword: 'Confirm New Password',
    confirmPassword: 'Confirm Password',
    enterNewPassword: 'Enter New Password',
    newPassword: 'New Password',
    oneSpecialCharacter: 'Have at least one special character (#$%@!)',
    oneNumber: 'Have at least one number',
    oneLowerCaseLetter: 'Have at least one lower case letter',
    oneUpperCaseLetter: 'Have at least one upper case letter',
    charactersLong: 'Be at least 8 characters long',
    points: 'Points',
    questionnairePreview: 'Questionnaire Preview',
    pleaseCreateQuestionnaire: 'Please create Questionnaire',
    pleaseReachOut: 'Please reach out to ',
    multipleBuildingsReachOut: 'for any questions regarding Multiple Buildings.',
    inProgress: 'Inprogress',
    lastEdited: 'Last Edited By',
    publishedDate: 'Published Date',
    createdDate: 'Created Date',
    buildingAsset: 'Building Asset',
    questionnaireDetail: 'Questionnaire Detail',
    duplicate: 'Duplicate',
    newVersion: 'New Version',
    notAvailable: 'Not Available',
    published: 'Published',
    archivedQuestionnaires: 'Archived Questionnaires',
    draftQuestionnaires: 'Draft Questionnaires',
    activeQuestionnaires: 'Active Questionnaires',
    archived: 'Archived',
    drafts: 'Drafts',
    active: 'Active',
    wantToPublish: 'Are you sure you want to Publish?',
    newQuestionnaireVersionName: 'Add New Questionnaire Version Name',
    createQuestionnaire: 'Create Questionnaire',
    selectKeyword: 'Select Keyword',
    addedSuccessfully: 'added successfully',
    welcomeNote: 'Welcome Note',
    selectPropertyType: 'Select Property Type',
    applicablePropertyType: 'Applicable Property Type',
    questionnaireDetails: 'Questionnaire Details',
    goBack: 'Go back',
    newQuestionnaireAdded: 'New questionnaire added successfully',
    successfullyDuplicatedQuestionnaire: 'You have successfully duplicated questionnaire.',
    questionnaireEdited: 'Questionnaire  edited successfully',
    applicableCountry: 'Applicable Country',
    propertyType: 'Property Type',
    certificationType: 'Certification Type',
    questionnaireNameOtherLanguage: 'Questionnaire Name in Other Language',
    enterQuestionnaireName: 'Enter Questionnaire Name',
    questionnaireName: 'Questionnaire Name',
    deletedSuccess: 'deleted successfully',
    wantToDeleteThis: 'Are you sure you want to delete this',
    creatingNewKeyword: 'Creating New Keyword',
    addKeyword: 'Add Keyword',
    creatingNewTopic: 'Creating New Topic',
    addTopic: 'Add Topic',
    creatingFocusArea: 'Creating New Focus Area',
    addFocusArea: 'Add Focus Area',
    questionLanguage: 'Question in other Language',
    addConditionalQuestions: 'Add Conditional Questions',
    editLanguage: 'Edit language',
    otherOption: 'Other Option',
    allowNotApplicable: 'Allow Not Applicable',
    mandatoryQuestion: 'Mandatory question',
    bonusQuestion: 'Bonus Question',
    bonusQuestions: 'Bonus Questions',
    allowAttachment: 'Allow attachment',
    addNewSegment: 'Add New Segment',
    segment: 'segment',
    toolTipDescription: 'Text for tool-tip description.',
    questionsType: 'Select Questions Type',
    writeQuestion: 'Write Question',
    newSegment: 'New Segment',
    questionDeleted: 'Question deleted successfully',
    wantDeleteThisQuestion: 'Are you sure you want to delete this question?',
    conditionalQuestion:
        "This can't be used for changing the sequence as this is a conditional question",
    text: 'Text',
    questionUpdated: 'Question updated successfully',
    questionAdded: 'Question added successfully',
    questionName: 'Question Name',
    languageUpdated: 'Language updated successfully',
    questionDescription: 'Question Description',
    this: 'This',
    keyword: 'Keyword',
    addOption: 'Add Option',
    categoryName: 'Category name',
    continueNextQuestion: 'Continue to the next question',
    newQuestionnaire: 'Creating New Questionnaire for',
    downloadQuestionnaireAs: 'Download questionnaire as:',
    reason: 'Reason',
    enterReason: 'Enter the Reason',
    confirmation: 'Confirmation',
    focusArea: 'Focus Area',
    focusAreaAdded: 'Focus area added successfully',
    publish: 'Publish',
    selectFocusArea: 'Select Focus Area',
    selectTopic: 'Select Topic',
    subcategoryType: 'Subcategory type',
    topic: 'Topic',
    questionnaireUsingPdfEditorOnly: 'Note: Answer questionnaire using pdf editor only',
    addAttachment: 'Add Attachment',
    addSegment: 'Add Segment',
    editSegment: 'Edit segment',
    addClientNote: 'Add Client Note',
    addInternalComment: 'Add Internal Comment',
    questionsFinalSubmission: 'Answer the following questions before final submission.',
    answered: 'Answered',
    answersSaved: 'Answers saved successfully',
    submitTheQuestionnaire: 'Are you sure you want to submit the questionnaire?',
    assignCertifiedLevel: 'Assign Certified Level',
    attachFiles: 'Attach files',
    attachFile: 'Attach File',
    attachment: 'Attachment',
    buildingCount: 'Building Count',
    buildingPortfolio: 'Building Portfolio',
    buildingsListForAccess: 'Buildings added in your list for access',
    certifiedBuildings: 'Certified Buildings',
    clientNoteAdded: 'Client Note Added',
    completed: 'Completed',
    confirm: 'Confirm',
    createNewBuilding: 'Create New Building',
    textForOtherOption: 'Enter text for Other option (Text is mandatory)',
    excel: 'Excel',
    exit: 'Exit',
    inProgresswith: 'In-Progress',
    inComplete: 'Incomplete',
    instructions: 'Instructions',
    internalComment: 'Internal Comment Added',
    unsavedChangesContinueWithoutSavingAnswers:
        'It looks like you might have some unsaved changes! Are you sure you want to continue without saving answers?',
    unsavedChangesBeforeRevert:
        'You have some unsaved changes. Please save them before proceeding with the revert to avoid losing your work.',
    newBuildingResults:
        'New building search results will be added at the bottom of the list. Scroll the table to get the latest information and select the building of your choice.',
    next: 'Next',
    noPortfolioDataFound: 'No Portfolio Data Found',
    noPortfolioDetailsFound: 'No Portfolio Details Found',
    notApplicable: 'Not Applicable',
    options: 'Options',
    pdfCompletionOffline: 'PDF (for completion offline)',
    portfolioName: 'Portfolio Name',
    portfolioNo: 'Portfolio No',
    preview: 'Preview',
    previous: 'Previous',
    previousUploadedFiles: 'Previous Uploaded Files',
    previosuUploadedFilesInfo: 'To attach files, please select them from your Library',
    libraryFiles: 'Library Files',
    searchPortfolios: 'Search Portfolios',
    selectionOfOneOfTheFileType: 'Selection of one of the file type',
    fileTypeNotSupported: 'File type is not supported',
    fileTypesSupported: 'File Types are Only Supported.',
    fileSizeRestriction: 'File size must be less than',
    submitForVerification: 'Submit For Verification',
    totalScore: 'Total Score',
    writeAComment: 'Write a comment',
    writeAnswer: 'Write answer',
    TermsAndConditions: 'Terms and Conditions',
    add: 'Add',
    addBuilding: 'Add Building',
    addMore: 'Add More',
    addNewUser: 'Add New User',
    addressLine1: 'Address line 1',
    addressLine2: 'Address line 2',
    allBuildings: 'All Buildings',
    apartmentBuilding: 'Apartment building, floor, etc',
    bBLocalAssociationMember: 'BB Local Association Member?',
    bbReadOnlyAdmin: 'BB Read Only Admin',
    bomaLocalAssociationMember: 'BOMA Local Association Member?',
    buildingImage: 'Building Image',
    buildings: 'Buildings',
    certificationExpiry: 'Certification Expiry',
    certificationScore: 'Certification Score',
    companyName: 'Company Name',
    companyType: 'Company Type',
    requireAFramedCertificate:
        'Do you require a framed certificate for your building upon a successful verification?',
    dontSeeYourCompanyInTheList: "Don't see your company in the list. Click here.",
    editBuilding: 'Edit Building',
    emailAddress: 'Email Address',
    enabled: 'Enabled',
    enterBuildingName: 'Enter Building Name',
    enterBuildingReferenceCode: 'Enter building reference code',
    enterCompanyName: 'Enter Company Name',
    enterLegacyID: 'Enter Legacy ID',
    enterPMCompany: 'Enter PM company',
    enterPMCompanyName: 'Enter PM company name',
    enterNumberOfUnits: 'Enter the number of units',
    enterUserName: 'Enter User Name',
    exportBuildings: 'Export Buildings',
    fileWillDownloadShortly: 'File will download shortly',
    findBuilding: 'Find Building',
    forExternalConsultants:
        'For external consultants, enter their consulting company’s name. If the Company Name is not listed, it can be added from the link below the box.',
    grossFloorAreaSqFt: 'Gross Floor Area(Sq ft)',
    iAccept: 'I accept the',
    incorrectEmailIDFormat:
        'Incorrect email ID format. Check the text in info icon next to invoice email address to identify the right format.',
    invoiceDetails: 'Invoice Details',
    lastModifiedBy: 'Last Modified By',
    locate: 'Locate',
    locateOnMap: 'Locate on Map',
    newBuildingRegistration: 'New Building Registration',
    noHistoricalDataFound: 'No Historical Data Found',
    noPreviewAvailable: 'No Preview Available',
    noQuestionnaireDataFound: 'No Questionnaire Data Found',
    noUserFound: 'No User Found',
    notCertified: 'Not Certified',
    numberOfFloors: 'Number of Floors',
    numberOfUnits: 'Number of Units',
    outOf: 'out of',
    ownershipStatus: 'Ownership Status',
    pmCompany: 'PM Company',
    poCompany: 'PO Company',
    portfolioStreamCertificates: 'Portfolio Stream Certificates',
    portfolioStreamCertificationType: 'Portfolio Stream Certification Type',
    privacyPolicy: 'Privacy Policy',
    privacyStatus: 'Privacy Status',
    progressIn: 'Progress (in %)',
    historicalDataFromOldProperty:
        'Provide access of historical data from old property management company to new management company users',
    provideBuildingAccess: 'Provide Building Access',
    questionnaireInProgress: 'Questionnaire In Progress',
    referenceCode: 'Reference Code',
    registered: 'Registered',
    reopenedQuestionnaire: 'Reopened Questionnaire',
    requestAccess: 'Request Access',
    requestBuildingAccess: 'Request Building Access',
    searchUsers: 'Search Users',
    select: 'select',
    selectBuildingType: 'Select Building Type',
    selectCompanyType: 'Select Company Type',
    groupAndTheTypeOfAccess: 'Select the group and the type of access to be provided for the user.',
    selectedBuildings: 'Selected Buildings',
    sendInvitation: 'Send Invitation',
    sendRequest: 'Send Request',
    singleStreamCertificates: 'Single Stream Certificates',
    singleStreamCertificationType: 'Single Stream Certification Type',
    stateProvinceCounty: 'State/Province/County',
    streetAddressPOBoxEtc: 'Street address, PO Box, etc',
    suggestedBuildings: 'Suggested Buildings',
    template: 'Template',
    testingBuilding: 'Test Building?',
    emailWillBeYourUsername: 'This email will be your username. Please use your official email.',
    addMultipleEmailIDs:
        'To add multiple email IDs separate them with a comma. For example, abc@xyz.com,def@xyz.com,ghi@xyz.com',
    uploadBuildingImage: 'Upload Building Image',
    uploadBuildings: 'Upload Buildings',
    userAccess: 'User Access',
    userDoesNotExist: 'User does not exist. Click here to add new user',
    userID: 'User ID',
    userInformation: 'User Information',
    userPermissions: 'User Permissions',
    verificationRequested: 'Verification Requested',
    verificationRequestedDate: 'Verification Requested Date',
    verifyBuilding: 'Verify Building',
    workingScoreIn: 'Working Score (in %)',
    yesNo: 'Yes/No',
    haveToUpdateAtLeastOne:
        'You have to update at least one, either "Property Management Company" or "Property Owner Company" to proceed.',
    yourbuildingReferenceCode: 'Your building reference code',
    zipPostalCode: 'Zip/Postal Code',
    peopleRecogniseYou: 'A picture helps people recognise you.',
    approvalPendingToUpdate: 'Admin approval pending to update Country and/or Local Association.',
    wantToDisableTheBuilding: 'Are you sure you want to disable the building?',
    assessment: 'Assessment',
    baseMembershipFee: 'Base Membership Fee',
    baseNonMembershipFee: 'Base Non Membership Fee',
    buildingAddedBy: 'Building Added By',
    buildingAddedOn: 'Building Added On',
    buildingHeight: 'Building Height',
    buildingIcon: 'building-icon',
    buildingLastEditedBy: 'Building Last Edited By',
    buildingLastEditedOn: 'Building Last Edited On',
    buildingSummary: 'Building Summary',
    buildingUpdated: 'Building updated successfully',
    buildingUsers: 'Building Users',
    certificate: 'Certificate',
    certificationDate: 'Certification Date',
    certificationQuestionnaire: 'Certification Questionnaire Name',
    changePassword: 'Change Password',
    contactNumber: 'Contact Number',
    continue: 'Continue',
    countryCode: 'Country Code',
    createInvoiceFee: 'Create Invoice Fee',
    creatingNewInvoiceFee: 'Creating a New Invoice Fee',
    current: 'Current',
    currentPassword: 'Current Password Here',
    disable: 'Disable',
    wantToEnableTheBuilding: 'Do you want to enable the building?',
    documents: 'Documents',
    downloadQuestionnaire: 'Download Questionnaire',
    editUserDetails: 'Edit User Details',
    editingInvoiceFee: 'Editing Invoice Fee',
    email: 'Email',
    enable: 'Enable',
    enterBuildingType: 'Enter Building Type',
    enterCurrentPassword: 'Enter Current Password',
    enterEmail: 'Enter Email',
    enterExtensionNumber: 'Enter Extension Number',
    enterFirstName: 'Enter First Name',
    enterJobTitle: 'Enter Job Title',
    enterLastName: 'Enter Last Name',
    enterSecondaryCompanyName: 'Enter secondary company name',
    expiryDate: 'Expiry Date',
    extensionNumber: 'Extension Number',
    finances: 'Finances',
    firstName: 'First Name',
    framedCertificate: 'Framed Certificate Required',
    historical: 'Historical',
    icon: 'icon',
    initiatedBy: 'Initiated By',
    invoiceAddress: 'Invoice Address',
    amountIsExclusive: 'Invoice amount is exclusive of applicable taxes',
    invoiceCompany: 'Invoice Company',
    invoiceInformation: 'Invoice Information',
    jobTitle: 'Job Title',
    lastModified: 'Last Modified',
    lastName: 'Last Name',
    legacyID: 'Legacy ID',
    membership: 'Membership',
    noInvoiceInformation: 'No Invoice Information Found',
    buildingDataShallNotBeAvailable:
        'Once disabled, all the building data shall not be available for benchmarking, reporting and dashboard.',
    pay: 'Pay',
    payNow: 'Pay Now',
    primaryCompanyName: 'Primary Company Name',
    primaryVerifierName: 'Primary Verifier Name',
    primaryVerifierOrganization: 'Primary Verifier Organization',
    profile: 'Profile',
    profileDetails: 'Profile Details',
    proflePicture: 'Profle Picture',
    propertyOwnerCompany: 'Property Owner Company',
    questionnaireResponse: 'Questionnaire Response',
    refresh: 'Refresh',
    refreshesInvoice: 'Refreshes invoice for Created invoice status',
    relatedQuestion: 'Related Question',
    scoreIn: 'Score (in %)',
    secondaryCompanyName: 'Secondary Company Name',
    secondaryVerifierName: 'Secondary Verifier Name',
    secondaryVerifierOrganization: 'Secondary Verifier Organization',
    selectCertificationType: 'Select Certification Type',
    selectCurrencyType: 'Select Currency Type',
    selectFeeType: 'Select Fee Type',
    selectLocalAssociation: 'Select Local Association',
    selectUserRole: 'Select user role',
    start: 'Start',
    answeringQuestionnaire: 'Start answering questionnaire',
    testBuilding: 'Test Building',
    timezone: 'Timezone',
    userDetails: 'User Details',
    userProfile: 'User Profile',
    userProfileIcon: 'user-profile-icon',
    userRole: 'User Role',
    variableMembershipFee: 'Variable Membership Fee',
    variableNonMembershipFee: 'Variable Non Membership Fee',
    verificationAssignedDate: 'Verification Assigned Date',
    verificationReport: 'Verification Report',
    viewCalendar: 'View Calendar',
    welcomeTo: 'Welcome to',
    fileWillGetDownloaded: 'Your file will get downloaded shortly.',
    passwordMustMatch: 'Your password and confirmation password must match.',
    addToList: 'Add to List',
    agingCategory: 'Aging Category',
    amount: 'Amount',
    applyPC: 'Apply PC',
    ascending: 'Ascending',
    bbAdmin: 'BB Admin',
    bbSuperAdmin: 'BB Super Admin',
    bbVerifier: 'BB Verifier',
    bbVerifierAdmin: 'BB Verifier Admin',
    teamReviewPromoCode: 'BOMA Admin team will review your promo code and update invoice value.',
    bomaDocuments: 'BOMA Documents',
    baseInvoiceFeeForMembers: 'Base Invoice fee for members',
    baseInvoiceFeeForNonMembers: 'Base Invoice fee for non-members',
    booked: 'Booked',
    browseFile: 'Browse File',
    buildingID: 'Building ID',
    buildingInvoices: 'Building Invoices',
    buildingReferenceCode: 'Building Reference Code',
    buildingType: 'Building Type',
    clear: 'Clear',
    companyDocuments: 'Company Documents',
    companySuperAdmin: 'Company Super Admin',
    createFilesFolders: 'Create Files and Folders',
    createFolder: 'Create Folder',
    createSubFolder: 'Create Sub Folder',
    currency: 'Currency',
    currencyType: 'Currency Type',
    delete: 'Delete',
    descending: 'Descending',
    download: 'Download',
    downloadInvoices: 'Download Invoices',
    editDescription: 'Edit Description',
    endDateIsRequired: 'End date is required',
    dateIsRequired: 'Date is required',
    enterBaseInvoiceFeeMembers: 'Enter base invoice fee for members',
    enterBaseInvoiceFeeNonMembers: 'Enter base invoice fee for non-members',
    enterVariableInvoiceMembers: 'Enter variable invoice fee for members',
    enterVariableInvoiceFeeNonMembers: 'Enter variable invoice fee for non-members',
    fail: 'Fail',
    feeType: 'Fee Type',
    files: 'Files',
    filterToDownloadInvoices: 'Filter to Download Invoices',
    filters: 'Filters',
    filterIcon: 'filter-icon',
    gOBack: 'Go Back',
    in: 'in',
    invoice: 'Invoice',
    invoiceCreationDate: 'Invoice Creation Date',
    invoiceDueDate: 'Invoice Due Date',
    invoiceUpdated: 'Invoice updated successfully',
    maxValue: 'Max Value',
    minValue: 'Min Value',
    modifiedDate: 'Modified Date',
    newVerifications: 'New Verifications',
    noBuildingFound: 'No Building Found',
    noInvoiceDetailsFound: 'No Invoice Details Found',
    noOfBuildings: 'No of Buildings',
    noOptionsFound: 'No options found',
    numberOfVerifications: 'Number of Verifications',
    onSiteInProgress: 'On-site - In Progress',
    openInNewTab: 'Open in New Tab',
    poUpdatedSuccessfully: 'PO updated successfully',
    pass: 'Pass',
    paymentDate: 'Payment Date',
    pleaseCreateAFolder: 'Please create a folder',
    preSiteReview: 'Pre-site Review',
    progress: 'Progress:',
    promoCode: 'Promo Code',
    promoCodeApplied: 'Promo Code applied successfully',
    propertyAdmin: 'Property Admin',
    propertyManagementCompany: 'Property Management Company',
    purchaseOrder: 'Purchase Order',
    questions: 'Questions',
    registrationType: 'Registration Type',
    reminder: 'Reminder',
    rename: 'Rename',
    search: 'Search',
    secondaryInProgress: 'Secondary In Progress',
    selectStatus: 'Select Status',
    send: 'Send',
    sort: 'Sort',
    startDateIsRequired: 'Start date is required',
    status: 'Status',
    subFolders: 'Sub-Folders',
    invoiceWillBeAdded: 'The following Invoice will be added to the list',
    today: 'Today',
    updateInvoice: 'Update Invoice',
    updatePO: 'Update PO',
    uploadFiles: 'Upload Files',
    variableInvoiceFeeMembers: 'Variable Invoice fee for members',
    variableInvoiceFeeNonMembers: 'Variable Invoice fee for non-members',
    verificationDone: 'Verification Done',
    verificationInProgress: 'Verification In-Progress',
    verifications: 'Verifications',
    verifierDocuments: 'Verifier Documents',
    withTax: 'With Tax',
    answerReport: 'Answer Report',
    apply: 'Apply',
    apr: 'Apr',
    asset: 'Asset',
    auditsCreated: 'Audits Created',
    aug: 'Aug',
    aveScorePerProperty: 'Ave. % Score per Property',
    avgEnergyCost: 'Average Energy Cost',
    avgEnergyStarScore: 'Average Energy Star Score',
    avgEnergyUse: 'Average Energy Use Intensity',
    avgGHGEmissions: 'Average GHG Emissions',
    avgWasteCost: 'Average Waste Cost',
    avgWasteDisposedAndDiverted: 'Average Waste Disposed and Diverted',
    avgWaterCost: 'Average Water Cost',
    avgWaterUseIntensity: 'Average Water Use Intensity',
    bomaBestBuildings: 'BOMA BEST Buildings',
    benchMarking: 'BenchMarking',
    benchmarking: 'Benchmarking',
    building: 'Building',
    buildingCertificate: 'Building Certificate',
    buildingsCertified: 'Buildings Certified',
    buildingsInVerification: 'Buildings in Verification',
    buildingsRegistered: 'Buildings Registered',
    buildingsVerified: 'Buildings Verified',
    certificationsExpired: 'Certifications Expired',
    countOfProperties: 'Count of Properties per Asset class registered with BOMA BEST',
    dashboard: 'Dashboard',
    dec: 'Dec',
    dontKnowOtherUnit: "Don't know/ Other (unit in percentage)",
    eenergyStarScore: 'ENERGY STAR SCORE',
    energy: 'Energy',
    energyCarbon: 'Energy and Carbon',
    energyCost: 'Energy Cost',
    energyCalendarMonthNotWeatherNormalized: 'Energy Use by Calendar Month(Not Weather Normalized)',
    enterOther: 'Enter other',
    feb: 'Feb',
    financials: 'Financials',
    fromDate: 'From Date',
    gHGEmissions: 'GHG Emissions',
    incomeCollectedToDate: 'Income Collected to Date',
    invoicesIssuedToDate: 'Invoices Issued to Date',
    jan: 'Jan',
    jul: 'Jul',
    jun: 'Jun',
    level: 'Level',
    mar: 'Mar',
    may: 'May',
    nA: 'NA',
    noDataFound: 'No Data Found',
    noOfAuditsCreated: 'No of Audits Created',
    selectMaxOf3Buildings: 'Note: Select a maximum of 3 Buildings',
    nov: 'Nov',
    numberOfBuildings: 'Number of Buildings',
    numberOfCertificationsByLevel: 'Number of Certifications by Level',
    numberOfCertifiedBuildings: 'Number of Certified Buildings',
    oct: 'Oct',
    outstandingRevenue: 'Outstanding Revenue',
    overview: 'Overview',
    paymentDue: 'Payment Due',
    portfolioStream: 'Portfolio Stream',
    potentialPoints: 'Potential Points',
    program: 'Program',
    q1JanMar: 'Q1 (Jan - Mar)',
    q2AprJun: 'Q2 (Apr - Jun)',
    q3JulSep: 'Q3 (Jul - Sep)',
    q4OctDec: 'Q4 (Oct - Dec)',
    quarter: 'Quarter',
    question: 'Question',
    questionnaire: 'Questionnaire',
    region: 'Region',
    score: 'Score',
    scoreReport: 'Score Report',
    selectAssetClass: 'Select Asset Class',
    selectBuilding: 'Select Building',
    selectCertification: 'Select Certification',
    selectLevel: 'Select Level',
    selectProgram: 'Select Program',
    selectQuarter: 'Select Quarter',
    selectQuestionnaire: 'Select Questionnaire',
    selectRegion: 'Select Region',
    selectStream: 'Select Stream',
    selectUser: 'Select User',
    selectYear: 'Select Year',
    sep: 'Sep',
    singleStream: 'Single Stream',
    stream: 'Stream',
    summary: 'Summary',
    summaryBuildingCount: 'Summary (Building Count',
    toDate: 'To Date',
    top5: 'Top 5',
    totalWasteGenerated: 'Total Waste Generated',
    Verification: 'Verification',
    verification: 'verification',
    view: 'View',
    waste: 'Waste',
    wasteAndMaterials: 'Waste and Materials Calendar Month',
    wasteManagementMethod: 'Waste by Management Method',
    wasteCost: 'Waste Cost',
    water: 'Water',
    waterCost: 'Water Cost',
    waterCalendarMonthNotWeatherNormalized: 'Water Use by Calendar Month(Not Weather Normalized)',
    year: 'Year',
    action: 'Action',
    addMeterReading: 'Add Meter Reading',
    addPropertyDetails: 'Add Property Details',
    address: 'Address',
    address2: 'Address 2',
    agencyDepartment: 'Agency Department Region',
    recGeneratedLocation: 'Arbitrage REC generated location',
    arbitrageValues: 'Arbitrage values',
    wantToDeleteMeter: 'Are you sure you want to delete this meter?',
    avgPercent: 'Average Percent Full',
    benchmarkingSummary: 'Benchmarking Summary',
    buildingAddress: 'Building Address',
    buildingInformation: 'Building Information',
    cancel: 'Cancel',
    cancelLowercase: 'cancel',
    close: 'Close',
    consumption: 'Consumption',
    cost: 'Cost',
    createNewMeter: 'Create a New Meter',
    demand: 'Demand',
    demandCost: 'Demand Cost',
    deleteInfo:
        'Are you sure you want to Delete?<br/>If you delete the file, it will be removed from all associated questionnaires.',
    disposalDestination: 'Disposal Destination',
    egridSubregion: 'eGRID Subregion',
    egridSubregionForUSA: 'eGRID Subregion for USA and Province for Canada',
    energyStarIntegration: 'ENERGY STAR Integration',
    energyStarScore: 'ENERGY STAR Score',
    endDate: 'End Date',
    endTime: 'End Time',
    energyExported: 'Energy Exported Off Site',
    energyOffSite: 'Energy Off Site',
    enterAddress1: 'Enter Address 1',
    enterAddress2: 'Enter Address 2',
    enterAgency: 'Enter Agency',
    enterAgencyDepartment: 'Enter Agency Department Region',
    enterCity: 'Enter City',
    enterEgridSubregion: 'Enter eGrid Subregion',
    enterFederalCampus: 'Enter Federal Campus',
    enterFederalOwner: 'Enter Federal Owner',
    generationPlantNumber: 'Enter generation plant number',
    enterIncineration: 'Enter Incineration',
    enterLandfill: 'Enter Landfill',
    enterPostalZipCode: 'Enter Postal/Zip Code',
    enterWasteToEnergy: 'Enter waste to energy',
    enterYearBuilt: 'Enter Year Built',
    estimatedValue: 'Estimated Value',
    countriesOtherThanUSACanada:
        'For countries other than the USA and Canada, enter your state name here',
    genarationLocation: 'Genaration Location',
    generationPlant: 'Generation Plant',
    inUse: 'In Use',
    incineration: 'Incineration',
    incinerationUnit: 'Incineration (unit in percentage)',
    isFederalPropertyBoolean: 'Is Federal Property (Boolean) ',
    isInstitutionalPropertyBoolean: 'Is Institutional Property Boolean',
    landfill: 'Landfill',
    localAssociation: 'Local Association',
    meterDeleted: 'Meter deleted successfully',
    meterInformation: 'Meter Information',
    metered: 'Metered',
    noBuildingsDataFound: 'No Buildings Data Found',
    noMetersHaveBeenAdded: 'No meters have been added.',
    noSync: 'No Sync',
    open: 'Open',
    other: 'Other',
    pleaseConfirm: 'Please Confirm',
    postalZipCode: 'Postal/Zip Code',
    propertyIsSetUpFor: 'Property is set up for',
    pull: 'Pull',
    quantity: 'Quantity',
    recOwnership: 'REC Ownership',
    sNo: 'S No',
    save: 'Save',
    saveLowercase: 'save',
    searchBuildings: 'Search Buildings',
    selectIsFederalProperty: 'Select Is Federal Property',
    selectIsInstitutionalProperty: 'Select Is Institutional Property',
    selectOccupancyPercentage: 'Select Occupancy Percentage',
    selectOtherState: 'Select Other State',
    selectState: 'Select State',
    specificPlant: 'Specific plant where energy was generated',
    startDate: 'Start Date',
    startTime: 'Start Time',
    disposedWasteGoesToEachDestination:
        'The sum of all disposable destination values should be equal to 100%. Update the table with correct values. ',
    timesEmptied: 'Times Emptied',
    totalEnergyCost: 'Total Energy Cost',
    totalGHGEmission: 'Total GHG Emission',
    totalWaste: 'Total Waste',
    totalWasteCost: 'Total Waste Cost',
    totalWaterCost: 'Total Water Cost',
    type: 'Type',
    usage: 'Usage',
    wasteToEnergy: 'Waste To Energy',
    wasteToEnergyUnit: 'Waste To Energy (unit in percentage)',
    waterUseIntensity: 'Water Use Intensity',
    weatherNormalizedSite: 'Weather Normalized Site EUI',
    shortNoteHere: 'Write the short note here',
    0: '0',
    JpegJpgPng: '.jpeg, .jpg and .png',
    APIGateway: 'API Gateway Timeout',
    accept: 'Accept',
    acceptTermsConditions: 'Accept terms and conditions',
    addLanguage: 'Add language',
    addVerificationQuestion: 'Add Verification Question',
    address1: 'Address 1',
    admin: 'Admin',
    administration: 'Administration',
    agency: 'Agency',
    agencyRegion: 'Agency Region',
    aggregateMeter: 'Aggregate Meter',
    aggregateMeterBoolean: 'Aggregate Meter (Boolean)',
    arbitrage: 'Arbitrage',
    areaUnit: 'Area Unit',
    badGateway: 'Bad Gateway',
    buildingName: 'Building Name',
    certification: 'Certification',
    certificationDetails: 'Certification Details',
    certificationLevel: 'Certification Level',
    certificationName: 'Certification Name',
    certificationNameInOtherLanguage: 'Certification Name in Other Language',
    certificationTypeEditedSuccessfully: 'Certification type edited successfully',
    certified: 'Certified',
    city: 'City',
    browseAttachFile: 'Click Browse File to attach file',
    clickHere: 'Click here',
    cubicMeters: 'cm (Cubic meters)',
    constructionProperty: 'Construction Property',
    constructionStatus: 'Construction Status',
    containerSize: 'Container Size',
    country: 'Country',
    countryName: 'Country Name',
    create: 'Create',
    createCertificationType: 'Create Certification Type',
    createCountry: 'Create Country',
    createLocalAssociation: 'Create Local Association',
    createVerificationQuestion: 'Create Verification Question',
    cubicYards: 'Cubic yards',
    dataEntryMethod: 'Data Entry Method',
    dataWillBeSentToEnergyStar:
        'Data will be sent to ENERGY STAR in Admin Company account for this property.',
    description: 'Description',
    descriptionTextHere: 'Description text here',
    edit: 'Edit',
    editCertificationLevel: 'Edit Certification Level',
    editCountry: 'Edit Country',
    editLocalAssociation: 'Edit Local Association',
    editVerificationQuestion: 'Edit Verification Question',
    enterCertificationName: 'Enter certification name',
    enterContainerSize: 'Enter Container Size',
    enterCountryName: 'Enter country name',
    enterID: 'Enter ID',
    enterIrrigatedArea: 'Enter Irrigated Area',
    enterLocalAssociation: 'Enter Local Association',
    enterMaximumPercentage: 'Enter Maximum Percentage',
    enterMeterName: 'Enter meter name',
    enterMinimumPercentage: 'Enter Minimum Percentage',
    enterNumberOfBuildings: 'Enter Number Of Buildings',
    enterPropertyName: 'Enter property name',
    enterUnit: 'Enter Unit',
    enterVerificationQuestion: 'Enter verification question',
    error: 'Error',
    externalConsultant: 'External Consultant',
    federalCampus: 'Federal Campus',
    federalOwner: 'Federal Owner',
    firstBillDate: 'First Bill Date',
    stepsProvidedByEnergyStar: 'Follow the steps provided by ENERGY STAR',
    gallonsUK: 'Gallons (UK)',
    gallonsUS: 'Gallons (US)',
    grossFloorArea: 'Gross Floor Area',
    grossFloorAreaAsOfDate: 'Gross Floor Area as of Date',
    grossFloorAreaCurrent: 'Gross Floor Area Current as of date',
    groupName: 'Group Name',
    howManyBuildings: 'How Many Buildings',
    imageFormatSupported: 'Image format supported : .jpeg, .jpg, .jpe and .png',
    imageSizeShouldNotExceed: 'Image size should not exceed 200 px * 110 px',
    inactiveDate: 'Inactive Date',
    internalServerError: 'Internal Server Error',
    irrigatedArea: 'Irrigated Area',
    isFederalProperty: 'Is Federal Property',
    isInstitutionalProperty: 'Is Institutional Property',
    isNotResponding: 'is not responding',
    isRequired: 'is required',
    language: 'Language',
    link: 'Link',
    liters: 'Liters',
    localAssociationName: 'Local Association name',
    multipleBuildingsCriteria: 'Multiple Buildings Criteria',
    makeSureYouHaveSharedYourBuilding:
        "Make sure you have shared your building with BOMA BEST's ENERGY STAR account. To do so follow the steps.",
    maxScore: 'max score',
    maximumPercentage: 'Maximum Percentage',
    meterEditedSuccessfully: 'Meter edited successfully',
    meterName: 'Meter Name',
    meterType: 'Meter Type',
    meterTypes: 'Meter Types',
    minScore: 'min score',
    minimumPercentage: 'Minimum Percentage',
    moreThanOne: 'More Than One',
    mustBeANumber: 'must be a number',
    mustBeAnInteger: 'must be an integer',
    mustBeGreaterThanOrEqualTo: 'must be greater than or equal to',
    mustBeInCorrectFormat: 'must be in correct format',
    mustBeLessThanOrEqualTo: 'must be less than or equal to',
    name: 'Name',
    newPasswordIsRequired: 'New password is required',
    no: 'No',
    noNotificationsDataFound: 'No Notifications Data Found',
    noPendingRequests: 'No Pending Requests',
    noSynchronizationWill: 'No synchronization will take place with ENERGY STAR for this property.',
    none: 'None',
    note: 'Note',
    notes: 'Notes',
    postedBy: 'Posted By',
    addNotes: 'Add Notes',
    notificationDateTime: 'Notification Date and Time',
    notificationName: 'Notification Name',
    notificationText: 'Notification Text',
    notifications: 'Notifications',
    occupancyPercentage: 'Occupancy Percentage',
    ok: 'Ok',
    bomaBestAccountAcceptsRequest:
        'Once BOMA BEST account accepts your request, you shall be able to sync the data from your portfolio manager ID.',
    one: 'One',
    otherDescription: 'Other Description',
    otherState: 'Other State',
    owned: 'Owned',
    passwordCanOnlyHaveMax: 'Password can only have maximum 16 characters',
    passwordMustHaveOneSpecialCharacter: 'Password must have one special character',
    passwordShouldHaveAtleastOneLowerCase: 'Password should have atleast one lower case letter',
    passwordShouldHaveAtleastOneNumber: 'Password should have atleast one number',
    passwordShouldHaveAtleastOneUpperCase: 'Password should have atleast one upper case letter',
    passwordShouldHaveMinimum8Characters: 'Password should have minimum 8 characters',
    permission: 'Permission',
    pleaseCreateCertificationType: 'Please create certification type',
    pleaseEnterValidEmailAddress: 'Please enter a valid email address',
    pleaseEnterAlphabetsNumbers: 'Please enter alphabets and numbers only',
    pleaseEnterAlphabetsOnly: 'Please enter alphabets only',
    portfolioID: 'Portfolio ID',
    portfolioManagerID: 'Portfolio Manager ID',
    portfolioManagerIDConnectToEnergyStar: 'Portfolio Manager ID to connect to ENERGY STAR',
    postalCode: 'Postal Code',
    propertyManagement: 'Property Management',
    propertyName: 'Property Name',
    propertyOwner: 'Property Owner',
    propertyReadOnlyUser: 'Property Read Only User',
    propertyUser: 'Property User',
    publishNow: 'Publish now',
    records: 'Records',
    readMore: 'Read More',
    redirectionLink: 'Redirection Link',
    reject: 'Reject',
    requestApproved: 'Request approved',
    requestDate: 'Request Date',
    requestRejected: 'Request rejected',
    SNo: 'S.No.',
    selectConstructionStatus: 'Select Construction Status',
    selectLanguage: 'Select Language',
    selectMeter: 'Select Meter',
    selectNumberOfBuildings: 'Select Number Of Buildings',
    selectUnitOfMeasure: 'Select Unit Of Measure',
    selectWasteMeterType: 'Select Waste Meter Type',
    serviceUnavailable: 'Service Unavailable',
    setupQuestionnaireExpiryDate: 'Setup questionnaire expiry date',
    shouldFollowTheFormat: 'Should follow the format "555-555-5555"',
    sold: 'Sold',
    squareFeet: 'Square Feet',
    state: 'State',
    submit: 'Submit',
    submitWithoutAssigning: 'Submit without assigning',
    submitWithoutAssigningSecondVerifier:
        'Are you sure you want to continue without assigning a secondary verifier?',
    success: 'Success',
    sustainableBuilding: 'Sustainable Building',
    typeOrPasteDescriptionHere: 'Type or paste description here',
    unableToAccessTheServer: 'Unable to access the server',
    unitOfMeasure: 'Unit Of Measure',
    update: 'Update',
    upload: 'Upload',
    username: 'Username',
    verificationQuestion: 'Verification Question',
    sendEmailNotifications: 'Send Email Notifications',
    verificationQuestionAdded: 'Verification question added successfully',
    verificationQuestionUpdated: 'Verification question updated successfully',
    verifier: 'Verifier',
    warning: 'Warning',
    wasteMeterType: 'Waste Meter Type',
    addingTheContact:
        'While adding the contact, search with username BOMA BEST and select the first option.',
    whops: 'Whops',
    yearBuilt: 'Year Built',
    yes: 'Yes',
    youCanFindYourPortfolioManagerID:
        "You can find your Portfolio Manager ID from your ENERGY STAR account. Login to your ENERGY STAR account, open the building for which ENERGY STAR integration is to be done and add the building's Portfolio Manager Property ID in the Portfolio Manager ID below.",
    forbiddenErrorMessage: `Oops, looks like there is a problem. Please delete your cookies by pressing 'Ctrl Shift R' and try logging in again. If the issue persists please contact `,
    permissionOfBuildingsShallBeRemoved:
        'Permission of buildings shall be removed which will have conflict with existing management or owner company. Company user’s user group shall remain the same.',
    permissionToAllBuildingsShallBeRemoved:
        'Permission to all the buildings shall be removed, as company is no longer a management or owner company. Company user’s user group shall be updated to Property User/Verifier.',
    permissionToAllBuildingsShallBeRemovedForExternal:
        'Permission to all buildings shall be removed, as company is no longer an external consultant and also not a manager/owner of any company. Company user’s user group shall remain as Property User.',
    permissionToAllBuildingsShallBeRemovedAndAssigned:
        'Permission to all buildings shall be removed and all users of the company will be assigned Verifier user group. Company user’s user group shall change to Verifier.',
    subFolder: 'Sub-Folder',
    folder: 'Folder',
    renamedSuccessfully: 'renamed successfully',
    wantToUpdateTheDetails: 'Are you sure you want to update the details?',
    enter: 'Enter',
    wantToSubmitYour: 'Are you sure you want to submit your',
    verificationResponse: 'Verification response?',
    verificationLog: 'Verification Log',
    primary: 'Primary',
    verificationSavedSuccessfully: 'verification saved successfully.',
    youHaveCompleted: 'You have completed',
    frankWright: 'frank.wright@bomacanada.ca',
    noCertificateDataFound: 'No Certificate Data Found',
    sno: 'S.No',
    exceededCharacterLimit: `You have exceeded your character limit`,
    minUnitCount: 'Min Unit Count',
    minAreaSqFt: 'Min Area (in sq ft)',
    maxUnitCount: 'Max Unit Count',
    maxAreaSqFt: 'Max Area (in sq ft)',
    enterMinUnitCount: 'Enter Min Unit Count',
    enterMaxUnitCount: 'Enter Max Unit Count',
    enterMinAreaSqFt: 'Enter Min Area (sq. ft)',
    enterMaxAreaSqFt: 'Enter Max Area (sq. ft)',
    minUnit: 'Min Unit',
    maxUnit: 'Max Unit',
    minArea: 'Min Area',
    maxArea: 'Max Area',
    invoiceFeeForMembers: 'Invoice fee for members',
    invoiceFeeForNonMembers: 'Invoice fee for non-members',
    file: 'File',
    sub: 'Sub ',
    verifierName: 'Verifier Name',
    makeYourIndividualFileSizeLessThan: 'Make sure your individual file size is less than',
    mb: 'MB',
    exceedFileSizeLimitOf: 'exceed file size limit of',
    on: 'On',
    off: 'Off',
    new: 'New',
    meter: 'Meter',
    nameInOtherLanguage: 'Name in Other Language',
    pleaseCreate: 'Please Create',
    list: 'list',
    approval: 'Approval',
    cost$: 'Cost ($)',
    demandCost$: 'Demand Cost ($)',
    adressline1: 'addressLine1',
    platinum: 'Platinum',
    bronze: 'Bronze',
    silver: 'Silver',
    gold: 'Gold',
    healthcare: 'Healthcare',
    openAirRetail: 'Open Air Retail',
    multiUnitResidentialBuildings: 'Multi Unit Residential Buildings',
    universal: 'Universal',
    lightIndustrial: 'Light Industrial',
    enclosedShoppingCenter: 'Enclosed Shopping Center',
    office: 'Office',
    selectAll: 'Select All',
    managementCompany: 'Management Company',
    assetType: 'Asset Type',
    certifiedBuildingList: 'Certified Building List',
    reset: 'Reset',
    noInformationAvailable: 'No information available',
    searchBuildingName: 'Search Building Name',
    countOfID: 'Count of ID',
    certificationsAwardedPerYear: 'Certifications Awarded per Year',
    bbBuildings: 'BOMA BEST Buildings',
    bbProperties: 'Certified BOMA BEST Properties',
    propertiesPerType: 'Properties per Type',
    featuredProject: 'Featured Project',
    certifiedBuildingAreaSquareFeet: 'Certified Building Area (in Square Feet)',
    feature: 'Feature',
    comments: 'Comments',
    comment: 'Comment',
    enableFeatureBuilding:
        'Are you sure you want to update this building as new featured building for public dashboard?',
    featureBuildingSuccessMessage:
        'You have successfully updated the featured building for public dashboard.',
    externalInitiator: 'External Initiator',
    externalModifier: 'External Modifier',
    countOfActiveCertificates: 'Count Of Active Certifications',
    activeCertificatesPerYear: 'Active Certifications per Year',
    verificationFileName: 'Verifications',
    viewMap: 'View Map',
    createAndEditBuildingMapInfo:
        'To change the pin drop location, click on View Map and Locate button first and then move the pin drop over the map (if required).',
    externalSubmittedBy: 'Externally Submitted by',
    bbCertifiedBuildings: 'BOMA BEST Certified Buildings',
    mapsAndGraphs: 'Maps and Graphs',
    bbCertifiedBuildingsList: 'BOMA BEST Certified Buildings List',
    submissionScore: 'Submission Score',
    verificationStatus: 'Verification Status',
    auditList: 'Audit List',
    noAuditDataFound: 'No Audit Data Found',
    enterValue: 'Enter value',
    selectValue: 'Select value',
    certifications: 'Certifications',
    certificateName: 'Certificate Name',
    assetTypeName: 'Asset Type',
    viewComments: 'View Comments',
    emptyCommentsListHeading: 'Your list of Comment are empty',
    emptyCommentsListSubHeading: `Your list of comment are empty because you haven’t added any comment yet.`,
    addComment: 'Add Comment',
    commentUpatedSuccessfully: 'Comment updated successfully',
    unsavedComment: 'Unsaved Comment',
    unsavedWorkInfo:
        'Make sure you save your work before refreshing the page. If not, all the unsaved answers, comments or flags will be lost if the page is refreshed without saving them',
    noCommentsAdded: 'No Comments Added',
    clientComments: 'Client Comments',
    internalComments: 'Internal Comments',
    addedBy: 'Added By',
    secondaryVerification: 'Secondary Verification',
    adminApprovalPending: 'Admin Approval Pending',
    buildingFailedByVerifier: 'Building Failed By Verifier',
    verificationFailedSuccessfully: 'Verification failed successfully',
    failedVerificationRevertedSuccessfully: 'Failed verification reverted successfully',
    auditsCreatedAnnually: ' Audits Created Annually',
    verificationStatusforCurrentYear: 'Verification Status for Current Year',
    auditsCreatedPerMonth: ' Audits Created per Month for Current Year',
    auditsAssignedPerVerifier: ' Audits Assigned per Verifier for Current Year',
    preSiteVerificationReport: 'Pre-Site Report',
    selectFiveVerifiers: 'Select any five verifiers',
    maxVerifiersSelected: 'Maximum verifiers selected',
    focusAreaName: 'Focus Area Name',
    enterFocusAreaName: 'Enter Focus Area Name',
    editFocusArea: 'Edit Focus Area',
    createFocusArea: 'Create Focus Area',
    topicName: 'Topic Name',
    enterTopicName: 'Enter Topic Name',
    editTopic: 'Edit Topic',
    createTopic: 'Create Topic',
    keywordsName: 'Keywords Name',
    enterKeywordsName: 'Enter Keywords Name',
    editKeywords: 'Edit Keywords',
    createKeywords: 'Create Keywords',
    focusAreaNameOtherLang: 'Focus Area Name in Other Language',
    topicNameOtherLang: 'Topic Name in Other Language',
    keywordsNameOtherLang: 'Keywords Name in Other Language',
    pleaseCreateFocusArealist: 'Please Create Focus Area list',
    pleaseCreateTopiclist: 'Please Create Topic list',
    pleaseCreateKeywordslist: 'Please Create Keywords list',
    addCertificationType: 'Add Certification Type',
    editCertificationType: 'Edit Certification Type',
    groupApproval: 'Group Approval',
    localAssociationApproval: 'Local Association Approval',
    countryApproval: 'Country Approval',
    buildingApproval: 'Building Approval',
    pleaseCreateCountry: 'Please Create Country',
    pleaseCreateLocalAssociation: 'Please Create Local Association',
    countryList: 'Country list',
    localAssociationList: 'Local Association list',
    newMeterEnergyConsumed: 'New Meter : Energy Consumed',
    editMeterEnergyConsumed: 'Edit Meter : Energy Consumed',
    newMeterEnergyProduced: 'New Meter : Energy Produced',
    editMeterEnergyProduced: 'Edit Meter : Energy Produced',
    newMeterWater: 'New Meter : Water',
    editMeterWater: 'Edit Meter : Water',
    newMeterWaste: 'New Meter : Waste',
    editMeterWaste: 'Edit Meter : Waste',
    noSynchronization: 'No Synchronization',
    pullFromENERGYSTAR: 'Pull from ENERGY STAR',
    pushToENERGYSTAR: 'Push to ENERGY STAR',
    propertyIsSetUpForPull: 'Property is set up for Pull',
    propertyIsSetUpForNoSync: 'Property is set up for No Sync',
    landfillUnitInPercentage: 'Landfill (unit in percentage)',
    folderName: 'Folder Name',
    fileName: 'File Name',
    enterFolderName: 'Enter Folder Name',
    enterFileName: 'Enter File Name',
    deleteFolder: 'Delete Folder',
    deleteFile: 'Delete File',
    deleteSubFolder: 'Delete Sub-Folder',
    editFolder: 'Edit Folder',
    editFile: 'Edit File',
    editSubFolder: 'Edit Sub Folder',
    createFile: 'Create File',
    onSiteFinalDate: 'Select on-site verification final date',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    energyStar: 'ENERGY STAR',
    datePlaceholder: 'YYYY/MM/DD',
    timePlaceholder: 'hh:mm:ss',
    commentSaved: 'Comment Saved',
    viewComment: 'View Comment',
    uploadDocument: 'Upload Document',
    answeringQuestionnaireValidation: 'Please answer the question before you attach files.',
    attachmentInfo: 'For verification, please upload the relevant documents.',
    showAttachedFiles: 'Show attached files',
    fileUploadInfo: 'click to upload from your device'
};
